define('ember-cli-segment/mixin', ['exports', 'ember'], function (exports, _ember) {
  'use strict';

  exports['default'] = _ember['default'].Mixin.create({
    init: function init() {
      this._super();
      if (!this.hasAnalytics()) {
        _ember['default'].Logger.warn('Segment.io is not loaded yet (window.analytics)');
      }
    },
    hasAnalytics: function hasAnalytics() {
      return !!(window.analytics && typeof window.analytics === "object");
    },

    log: function log() {
      if (this.config && this.config.segment && this.config.segment.LOG_EVENT_TRACKING) {
        _ember['default'].Logger.info('[Segment.io] ', arguments);
      }
    },

    trackPageView: function trackPageView() {
      if (this.hasAnalytics()) {
        window.analytics.page.apply(this, arguments);

        this.log('trackPageView', arguments);
      }
    },

    trackEvent: function trackEvent(event, properties, options, callback) {
      if (this.hasAnalytics()) {
        window.analytics.track(event, properties, options, callback);
        this.log(event, properties, options);
      }
    },

    identifyUser: function identifyUser(userId, traits, options, callback) {
      if (this.hasAnalytics()) {
        window.analytics.identify(userId, traits, options, callback);
        this.log('identifyUser', traits, options);
      }
    },

    group: function group(groupId, traits, options, callback) {
      if (this.hasAnalytics()) {
        window.analytics.group(groupId, traits, options, callback);
        this.log('group', traits, options);
      }
    },

    aliasUser: function aliasUser(userId, previousId, options, callback) {
      if (this.hasAnalytics()) {
        window.analytics.alias(userId, previousId, options, callback);
        this.log('aliasUser', previousId, options);
      }
    }
  });
});