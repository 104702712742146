define('npm:@mapbox/mapbox-gl-draw-static-mode', function(){ return { 'default': require('@mapbox/mapbox-gl-draw-static-mode')};})
define('npm:async', function(){ return { 'default': require('async')};})
define('npm:bugsnag-js', function(){ return { 'default': require('bugsnag-js')};})
define('npm:chartist', function(){ return { 'default': require('chartist')};})
define('npm:chartist-plugin-axistitle', function(){ return { 'default': require('chartist-plugin-axistitle')};})
define('npm:chartist-plugin-legend', function(){ return { 'default': require('chartist-plugin-legend')};})
define('npm:chartist-plugin-tooltips', function(){ return { 'default': require('chartist-plugin-tooltips')};})
define('npm:chartist-plugin-zoom', function(){ return { 'default': require('chartist-plugin-zoom')};})
define('npm:exifreader', function(){ return { 'default': require('exifreader')};})
define('npm:html2canvas', function(){ return { 'default': require('html2canvas')};})
define('npm:jquery-nearest', function(){ return { 'default': require('jquery-nearest')};})
define('npm:lodash', function(){ return { 'default': require('lodash')};})
define('npm:pdf-lib', function(){ return { 'default': require('pdf-lib')};})
define('npm:polylabel', function(){ return { 'default': require('polylabel')};})
define('npm:proj4', function(){ return { 'default': require('proj4')};})
define('npm:react', function(){ return { 'default': require('react')};})
define('npm:react-dom', function(){ return { 'default': require('react-dom')};})
define('npm:xlsx', function(){ return { 'default': require('xlsx')};})