define("ember-models-table/mixins/hover-support", ["exports"], function (exports) {

  /**
   * @class HoverSupport
   * @namespace Mixins
   */
  "use strict";

  exports["default"] = Ember.Mixin.create({
    enter: function enter() {},
    leave: function leave() {},
    mouseEnter: function mouseEnter() {
      this.enter();
    },
    focusIn: function focusIn() {
      this.enter();
    },
    mouseLeave: function mouseLeave() {
      this.leave();
    },
    focusOut: function focusOut() {
      this.leave();
    }
  });
});