define('ember-cli-segment/initializer', ['exports', 'ember', 'ember-cli-segment/mixin'], function (exports, _ember, _emberCliSegmentMixin) {
  'use strict';

  exports['default'] = initialize;

  function initialize() {
    // 1.13 and 2.x support
    var application = arguments[1] || arguments[0];
    var segment = _ember['default'].Object.extend(_emberCliSegmentMixin['default']);
    application.register('service:segment', segment, { singleton: true });
    application.inject('route', 'segment', 'service:segment');
    application.inject('router', 'segment', 'service:segment');
    application.inject('controller', 'segment', 'service:segment');
  }
});