define('ember-models-table/components/models-table/cell-content-display', ['exports', 'ember-models-table/templates/components/models-table/cell-content-display'], function (exports, _emberModelsTableTemplatesComponentsModelsTableCellContentDisplay) {
  'use strict';

  /**
   * Default cell-component. Used to print property-value (`row` - source, `column.propertyName` - key).
   *
   * Properties and event-handlers from [models-table/cell](Components.ModelsTableCell.html) are bound here
   *
   * @namespace Components
   * @class ModelsTableCellContentDisplay
   * @extends Ember.Component
   */
  exports['default'] = Ember.Component.extend({
    layout: _emberModelsTableTemplatesComponentsModelsTableCellContentDisplay['default'],

    init: function init() {
      Ember.set(this, 'tagName', Ember.get(this, 'themeInstance.tagNames.cell-content'));
      this._super.apply(this, arguments);
    }
  });
});