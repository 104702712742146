define('ember-models-table/components/models-table/select', ['exports', 'ember-models-table/templates/components/models-table/select'], function (exports, _emberModelsTableTemplatesComponentsModelsTableSelect) {
  'use strict';

  /**
   * Select-dropdown with a list of options. Used as page-size select and for select-filters.
   *
   * @class ModelsTableSelect
   * @namespace Components
   * @extends Ember.Component
   * @private
   */
  exports['default'] = Ember.Component.extend({

    layout: _emberModelsTableTemplatesComponentsModelsTableSelect['default'],
    tagName: 'select',
    classNameBindings: ['cssPropertyName', 'themeInstance.input', 'themeInstance.select'],

    /**
     * @type string
     * @default ''
     * @property type
     */
    type: '',

    /**
     * @type string
     * @default ''
     * @property cssPropertyName
     */
    cssPropertyName: '',

    /**
     * Bound from {{#crossLink "Components.ModelsTable/themeInstance:property"}}ModelsTable.themeInstance{{/crossLink}}
     *
     * @property themeInstance
     * @type object
     * @default null
     */
    themeInstance: null,

    change: function change() {
      this.set('value', this.$('option:selected').val());
    }
  });
});