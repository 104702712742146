define("ember-models-table/templates/components/models-table/themes/semanticui/pagination-simple", ["exports"], function (exports) {
  "use strict";

  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      return {
        meta: {
          "fragmentReason": {
            "name": "missing-wrapper",
            "problems": ["wrong-type"]
          },
          "revision": "Ember@2.5.0",
          "loc": {
            "source": null,
            "start": {
              "line": 1,
              "column": 0
            },
            "end": {
              "line": 15,
              "column": 0
            }
          },
          "moduleName": "modules/ember-models-table/templates/components/models-table/themes/semanticui/pagination-simple.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
          return morphs;
        },
        statements: [["inline", "yield", [["subexpr", "hash", [], ["goToPage", ["get", "goToPage", ["loc", [null, [4, 15], [4, 23]]]], "gotoFirst", ["subexpr", "action", ["gotoFirst"], [], ["loc", [null, [5, 16], [5, 36]]]], "gotoPrev", ["subexpr", "action", ["gotoPrev"], [], ["loc", [null, [6, 15], [6, 34]]]], "gotoNext", ["subexpr", "action", ["gotoNext"], [], ["loc", [null, [7, 15], [7, 34]]]], "gotoLast", ["subexpr", "action", ["gotoLast"], [], ["loc", [null, [8, 15], [8, 34]]]], "gotoBackEnabled", ["get", "gotoBackEnabled", ["loc", [null, [9, 22], [9, 37]]]], "gotoForwardEnabled", ["get", "gotoForwardEnabled", ["loc", [null, [10, 25], [10, 43]]]], "sendAction", ["get", "sendAction", ["loc", [null, [11, 17], [11, 27]]]], "themeInstance", ["get", "themeInstance", ["loc", [null, [12, 20], [12, 33]]]]], ["loc", [null, [3, 4], [13, 5]]]]], [], ["loc", [null, [2, 2], [14, 4]]]]],
        locals: [],
        templates: []
      };
    })();
    var child1 = (function () {
      return {
        meta: {
          "fragmentReason": false,
          "revision": "Ember@2.5.0",
          "loc": {
            "source": null,
            "start": {
              "line": 15,
              "column": 0
            },
            "end": {
              "line": 28,
              "column": 0
            }
          },
          "moduleName": "modules/ember-models-table/templates/components/models-table/themes/semanticui/pagination-simple.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("button");
          var el3 = dom.createTextNode("\n      ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("i");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("button");
          var el3 = dom.createElement("i");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("button");
          var el3 = dom.createElement("i");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("button");
          var el3 = dom.createElement("i");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n  ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element0 = dom.childAt(fragment, [1]);
          var element1 = dom.childAt(element0, [1]);
          var element2 = dom.childAt(element1, [1]);
          var element3 = dom.childAt(element0, [3]);
          var element4 = dom.childAt(element3, [0]);
          var element5 = dom.childAt(element0, [5]);
          var element6 = dom.childAt(element5, [0]);
          var element7 = dom.childAt(element0, [7]);
          var element8 = dom.childAt(element7, [0]);
          var morphs = new Array(13);
          morphs[0] = dom.createAttrMorph(element0, 'class');
          morphs[1] = dom.createAttrMorph(element1, 'class');
          morphs[2] = dom.createElementMorph(element1);
          morphs[3] = dom.createAttrMorph(element2, 'class');
          morphs[4] = dom.createAttrMorph(element3, 'class');
          morphs[5] = dom.createElementMorph(element3);
          morphs[6] = dom.createAttrMorph(element4, 'class');
          morphs[7] = dom.createAttrMorph(element5, 'class');
          morphs[8] = dom.createElementMorph(element5);
          morphs[9] = dom.createAttrMorph(element6, 'class');
          morphs[10] = dom.createAttrMorph(element7, 'class');
          morphs[11] = dom.createElementMorph(element7);
          morphs[12] = dom.createAttrMorph(element8, 'class');
          return morphs;
        },
        statements: [["attribute", "class", ["get", "themeInstance.paginationBlock", ["loc", [null, [16, 15], [16, 44]]]]], ["attribute", "class", ["concat", [["get", "themeInstance.buttonDefault", ["loc", [null, [17, 44], [17, 71]]]], " ", ["subexpr", "if", [["get", "gotoBackEnabled", ["loc", [null, [17, 79], [17, 94]]]], "enabled", "disabled"], [], ["loc", [null, [17, 74], [17, 117]]]]]]], ["element", "action", ["gotoFirst"], [], ["loc", [null, [17, 12], [17, 34]]]], ["attribute", "class", ["get", "themeInstance.nav-first", ["loc", [null, [18, 17], [18, 40]]]]], ["attribute", "class", ["concat", [["get", "themeInstance.buttonDefault", ["loc", [null, [19, 43], [19, 70]]]], " ", ["subexpr", "if", [["get", "gotoBackEnabled", ["loc", [null, [19, 78], [19, 93]]]], "enabled", "disabled"], [], ["loc", [null, [19, 73], [19, 116]]]]]]], ["element", "action", ["gotoPrev"], [], ["loc", [null, [19, 12], [19, 33]]]], ["attribute", "class", ["get", "themeInstance.nav-prev", ["loc", [null, [20, 14], [20, 36]]]]], ["attribute", "class", ["concat", [["get", "themeInstance.buttonDefault", ["loc", [null, [22, 15], [22, 42]]]], " ", ["subexpr", "if", [["get", "gotoForwardEnabled", ["loc", [null, [22, 50], [22, 68]]]], "enabled", "disabled"], [], ["loc", [null, [22, 45], [22, 91]]]]]]], ["element", "action", ["gotoNext"], [], ["loc", [null, [21, 13], [21, 34]]]], ["attribute", "class", ["get", "themeInstance.nav-next", ["loc", [null, [23, 14], [23, 36]]]]], ["attribute", "class", ["concat", [["get", "themeInstance.buttonDefault", ["loc", [null, [25, 15], [25, 42]]]], " ", ["subexpr", "if", [["get", "gotoForwardEnabled", ["loc", [null, [25, 50], [25, 68]]]], "enabled", "disabled"], [], ["loc", [null, [25, 45], [25, 91]]]]]]], ["element", "action", ["gotoLast"], [], ["loc", [null, [24, 12], [24, 33]]]], ["attribute", "class", ["get", "themeInstance.nav-last", ["loc", [null, [26, 14], [26, 36]]]]]],
        locals: [],
        templates: []
      };
    })();
    return {
      meta: {
        "fragmentReason": {
          "name": "missing-wrapper",
          "problems": ["wrong-type"]
        },
        "revision": "Ember@2.5.0",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 28,
            "column": 7
          }
        },
        "moduleName": "modules/ember-models-table/templates/components/models-table/themes/semanticui/pagination-simple.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var morphs = new Array(1);
        morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
        dom.insertBoundary(fragment, 0);
        dom.insertBoundary(fragment, null);
        return morphs;
      },
      statements: [["block", "if", [["get", "hasBlock", ["loc", [null, [1, 6], [1, 14]]]]], [], 0, 1, ["loc", [null, [1, 0], [28, 7]]]]],
      locals: [],
      templates: [child0, child1]
    };
  })());
});