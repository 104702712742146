define('ember-composable-helpers/helpers/toggle-action', ['exports', 'ember-composable-helpers/helpers/toggle', 'ember-composable-helpers/-private/closure-action'], function (exports, _emberComposableHelpersHelpersToggle, _emberComposableHelpersPrivateClosureAction) {
  'use strict';

  var helper = Ember.Helper.helper;

  var closureToggle = _emberComposableHelpersHelpersToggle.toggle;
  if (_emberComposableHelpersPrivateClosureAction['default']) {
    closureToggle[_emberComposableHelpersPrivateClosureAction['default']] = true;
  }

  exports['default'] = helper(closureToggle);
});