define("ember-models-table/components/models-table", ["exports", "ember-models-table/utils/better-compare", "ember-models-table/themes/bootstrap3", "ember-models-table/templates/components/models-table", "ember-models-table/utils/column"], function (exports, _emberModelsTableUtilsBetterCompare, _emberModelsTableThemesBootstrap3, _emberModelsTableTemplatesComponentsModelsTable, _emberModelsTableUtilsColumn) {
  "use strict";

  var _slicedToArray = (function () {
    function sliceIterator(arr, i) {
      var _arr = [];var _n = true;var _d = false;var _e = undefined;try {
        for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
          _arr.push(_s.value);if (i && _arr.length === i) break;
        }
      } catch (err) {
        _d = true;_e = err;
      } finally {
        try {
          if (!_n && _i["return"]) _i["return"]();
        } finally {
          if (_d) throw _e;
        }
      }return _arr;
    }return function (arr, i) {
      if (Array.isArray(arr)) {
        return arr;
      } else if (Symbol.iterator in Object(arr)) {
        return sliceIterator(arr, i);
      } else {
        throw new TypeError("Invalid attempt to destructure non-iterable instance");
      }
    };
  })();

  function _toConsumableArray(arr) {
    if (Array.isArray(arr)) {
      for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
        arr2[i] = arr[i];
      }return arr2;
    } else {
      return Array.from(arr);
    }
  } /* eslint ember/closure-actions: 0 */

  /**
   * @typedef {object} groupedHeader
   * @property {string} title header for grouped columns
   * @property {number} colspan HTML colspan attr
   * @property {number} rowspan HTML rowspan attr
   */

  var keys = Object.keys;

  var NOT_SORTED = -1;

  /**
   * @ignore
   * @param {ModelsTableColumn} column
   * @returns {boolean}
   */
  function isSortedByDefault(column) {
    return column.sortPrecedence > NOT_SORTED;
  }

  /**
   * Default filter-function used in the filter by columns
   *
   * @param {string} cellValue value in the table cell
   * @param {string} filterString needed substring
   * @returns {boolean}
   * @ignore
   */
  function defaultFilter(cellValue, filterString) {
    return -1 !== cellValue.indexOf(filterString);
  }

  /**
   * Convert some string to the human readable one
   *
   * @param {string} name value to convert
   * @returns {string}
   * @ignore
   */
  function propertyNameToTitle(name) {
    return Ember.String.capitalize(Ember.String.dasherize(name).replace(/-/g, ' '));
  }

  /**
   * @ignore
   * @param {string} option
   * @returns {{value: *, label: *}}
   */
  function optionStrToObj(option) {
    return { value: option, label: option };
  }

  /**
   * Updates <code>filterOptions</code> for column which use <code>filterWithSelect</code>
   * and don't have <code>predefinedFilterOptions</code>
   * <code>filterOptions</code> are calculated like <code>data.mapBy(column.propertyName).uniq()</code>,
   * where data is component's <code>data</code>
   *
   * @param {string} propertyName
   * @returns {object[]}
   * @ignore
   */
  function getFilterOptionsCP(propertyName) {
    return Ember.computed('data.@each.' + propertyName, function () {
      var data = Ember.get(this, 'data');
      var predefinedFilterOptions = Ember.get(this, 'predefinedFilterOptions');
      var filterWithSelect = Ember.get(this, 'filterWithSelect');
      if (filterWithSelect && 'array' !== Ember.typeOf(predefinedFilterOptions)) {
        var _data = Ember.A(Ember.A(data).compact());
        var options = Ember.A(_data.mapBy(propertyName)).compact();
        if (Ember.get(this, 'sortFilterOptions')) {
          options = options.sort();
        }
        return Ember.A(Ember.A([''].concat(_toConsumableArray(options))).uniq().map(optionStrToObj));
      }
      return [];
    });
  }

  function chunkBy(collection, propertyName, sortOrder) {
    var doSort = arguments.length === 3;
    var chunks = Ember.A([]);
    var values = [];
    if (!Ember.isArray(collection)) {
      return chunks;
    }
    collection.forEach(function (item) {
      var value = Ember.get(item, propertyName);
      if (values.indexOf(value) === -1) {
        values.push(value);
        chunks.push(Ember.A([]));
      }
      var index = values.indexOf(value);
      chunks[index].pushObject(item);
    });
    if (doSort) {
      var sortedValues = values.slice().sort(function (v1, v2) {
        var result = (0, _emberModelsTableUtilsBetterCompare["default"])(v1, v2);
        if (result !== 0) {
          return sortOrder === 'desc' ? -1 * result : result;
        }
        return 0;
      });
      return sortedValues.map(function (v) {
        return chunks[values.indexOf(v)];
      });
    }
    return chunks;
  }

  function objToArray(map) {
    var ret = [];
    if (Ember.isArray(map)) {
      map.forEach(function (m) {
        ret = [].concat(_toConsumableArray(ret), _toConsumableArray(m));
      });
      return ret;
    }
    keys(map).forEach(function (k) {
      if (Ember.isArray(map[k])) {
        ret = [].concat(_toConsumableArray(ret), _toConsumableArray(map[k]));
      }
    });
    return ret;
  }

  /**
   * Table-component with pagination, sorting and filtering.
   *
   * It should be used when whole dataset is already loaded. For server-side pagination, filtering and sorting
   * [models-table-server-paginated](Components.ModelsTableServerPaginated.html) should be used.
   *
   * Basic usage example:
   *
   * ```hbs
   * {{models-table data=model columns=columns}}
   * ```
   *
   * Usage with block context:
   *
   * ```hbs
   * {{#models-table data=data columns=columns as |mt|}}
   *   {{mt.global-filter}}
   *   {{mt.data-group-by-select}}
   *   {{mt.columns-dropdown}}
   *   {{mt.table}}
   *   {{mt.footer}}
   * {{/models-table}}
   * ```
   *
   * ModelsTable yields references to the following contextual components:
   *
   * * [models-table/global-filter](Components.ModelsTableGlobalFilter.html) - global filter used for table data
   * * [models-table/columns-dropdown](Components.ModelsTableColumnsDropdown.html) - dropdown with list of options to toggle columns and column-sets visibility
   * * [models-table/data-group-by-select](Components.ModelsTableDataGroupBySelect.html) - dropdown to select property for table-rows grouping
   * * [models-table/table](Components.ModelsTableTable.html) - table with a data
   * * [models-table/footer](Components.ModelsTableFooter.html) - summary and pagination
   *
   * Check own docs for each component to get detailed info.
   *
   * ModelsTable has a lot of options you may configure, but there are two required properties called `data` and `columns`. First one contains data (e.g. list of records from the store). Second one is a list of table's columns (check [models-table-column](Utils.ModelsTableColumn.html) for available options).
   *
   * @namespace Components
   * @class ModelsTable
   * @extends Ember.Component
   */
  exports["default"] = Ember.Component.extend({

    layout: _emberModelsTableTemplatesComponentsModelsTable["default"],

    classNames: ['models-table-wrapper'],

    /**
     * Number of records shown on one table-page
     *
     * @type number
     * @property pageSize
     * @default 10
     */
    pageSize: 10,

    /**
     * Currently shown page number. It may be set initially
     *
     * @type number
     * @property currentPageNumber
     * @default 1
     */
    currentPageNumber: 1,

    /**
     * List of properties to sort table rows
     *
     * Each value is like 'propertyName:sortDirection'
     *
     * @type string[]
     * @property sortProperties
     * @default []
     * @private
     */
    sortProperties: Ember.computed(function () {
      return Ember.A([]);
    }),

    /**
     * @type string[]
     * @default ['processedColumns.@each.filterString', 'filterString', 'pageSize']
     * @private
     * @readonly
     */
    forceToFirstPageProps: Ember.computed(function () {
      return Ember.A(['processedColumns.@each.filterString', 'filterString', 'pageSize']);
    }).readOnly(),

    /**
     * Determines if multi-columns sorting should be used
     *
     * @type boolean
     * @property multipleColumnsSorting
     * @default false
     */
    multipleColumnsSorting: true,

    /**
     * Determines if component footer should be shown on the page
     *
     * @type boolean
     * @property showComponentFooter
     * @default true
     */
    showComponentFooter: true,

    /**
     * Determines if numeric pagination should be used
     *
     * @type boolean
     * @property useNumericPagination
     * @default false
     */
    useNumericPagination: false,

    /**
     * Determines if columns-dropdown should be shown
     *
     * @type boolean
     * @property showColumnsDropdown
     * @default true
     */
    showColumnsDropdown: true,

    /**
     * Determines if filtering by columns should be available to the user
     *
     * @type boolean
     * @property useFilteringByColumns
     * @default true
     */
    useFilteringByColumns: true,

    /**
     * Global filter value
     *
     * @type string
     * @property filterString
     * @default ''
     */
    filterString: '',

    /**
     * Determines if filtering (global and by column) should ignore case
     *
     * @type boolean
     * @property filteringIgnoreCase
     * @default false
     */
    filteringIgnoreCase: false,

    /**
     * Determines if filtering should be done by hidden columns
     *
     * **Notice:** after changing this value filtering results will be updated only after filter options are changed
     *
     * @type boolean
     * @property doFilteringByHiddenColumns
     * @default true
     */
    doFilteringByHiddenColumns: true,

    /**
     * Determines if 'Global filter'-field should be shown
     *
     * @type boolean
     * @property showGlobalFilter
     * @default true
     */
    showGlobalFilter: true,

    /**
     * Determines if focus should be on the 'Global filter'-field on component render
     *
     * @type boolean
     * @property focusGlobalFilter
     * @default false
     */
    focusGlobalFilter: false,

    /**
     * Determines if <code>processedColumns</code> will be updated if <code>columns</code> are changed (<code>propertyName</code> and
     * <code>template</code> are observed)
     * <b>IMPORTANT</b> All filter, sort and visibility options will be dropped to the default values while updating
     *
     * @type boolean
     * @property columnsAreUpdateable
     * @default false
     */
    columnsAreUpdateable: false,

    /**
     * Determines if rows should be grouped for some property
     *
     * Grouped value may be shown in the separated row on the top of the group or in the first column (in the cell with rowspan) in the each group (see {{#crossLink 'Components.ModelsTable/displayGroupedValueAs:property'}}displayGroupedValueAs{{/crossLink}})
     *
     * Generally you should not show column with property which is used for grouping (but it's up to you)
     *
     * @property useDataGrouping
     * @type boolean
     * @default false
     */
    useDataGrouping: false,

    /**
     * Property name used now for grouping rows
     *
     * **IMPORTANT** It should be set initially if {{#crossLink 'Components.ModelsTable/useDataGrouping:property'}}useDataGrouping{{/crossLink}} is set to `true`
     *
     * @property currentGroupingPropertyName
     * @type string
     * @default null
     */
    currentGroupingPropertyName: null,

    /**
     * Sort direction for grouped property values
     *
     * @property sortByGroupedFieldDirection
     * @type string
     * @default 'asc'
     * @private
     */
    sortByGroupedFieldDirection: 'asc',

    /**
     * Determines how grouped value will be displayed - as a row or column
     *
     * Allowed values are `row` and `column`
     *
     * @property displayGroupedValueAs
     * @type string
     * @default 'row'
     */
    displayGroupedValueAs: 'row',

    /**
     * <code>columns</code> fields which are observed to update shown table-columns
     * It is used only if <code>columnsAreUpdateable</code> is <code>true</code>
     *
     * @type string[]
     * @property columnFieldsToCheckUpdate
     * @default ['propertyName', 'component']
     */
    columnFieldsToCheckUpdate: Ember.computed(function () {
      return Ember.A(['propertyName', 'component']);
    }),

    /**
     * `themeInstance` is an instance of [DefaultTheme](Themes.Default.html) or it's children.
     * By default `models-table` uses [BootstrapTheme](Themes.Bootstrap.html) instance.
     *
     * You may create your own theme-class and set `themeInstance` to it's instance. Check Theme properties you may define in your own theme.
     *
     * @type Themes.Default
     * @property themeInstance
     */
    themeInstance: Ember.computed(function () {
      return _emberModelsTableThemesBootstrap3["default"].create();
    }),

    /**
     * All table records
     *
     * It's a first of the two attributes you must set to the component
     *
     * @type object[]
     * @property data
     * @default []
     */
    data: Ember.computed(function () {
      return Ember.A([]);
    }),

    /**
     * Table columns. Check [ModelsTableColumn](Utils.ModelsTableColumn.html) for available properties
     *
     * It's a second of the two attributes you must set to the component
     *
     * @type object[]
     * @property columns
     * @default []
     */
    columns: Ember.computed(function () {
      return Ember.A([]);
    }),

    /**
     * Hash of components to be used for columns.
     *
     * See [ModelsTableColumn](Utils.ModelsTableColumn.html), property component
     *
     * @type Object
     * @property columnComponents
     * @default {}
     */
    columnComponents: Ember.computed({
      get: function get() {
        return {};
      },
      set: function set(k, v) {
        return v;
      }
    }),

    /**
     * Sets of columns that can be toggled together.
     * Each object should have:
     *  * `label` (string) - The label for the set. This will be displayed in the columns dropdown.
     *  * `showColumns` (array|Function) - This should either be an array of `propertyNames` to show, or a function. If it is a function, the function will be called with the `processedColumns` as attribute.
     *  * `hideOtherColumns` (boolean) -  If this is true (default), all columns not specified in <code>showColumns</code> will be hidden. If this is set to false, other columns will be left at whatever visibility they were before.
     *  * `toggleSet` (boolean) - If this is true (default is false), the set columns will be shown if one of them is currently hidden,
     else they will all be hidden. Settings this will result in a default of `hideOtherColumns=false`
     *
     * @type Object[]
     * @property columnSets
     * @default []
     */
    columnSets: Ember.computed(function () {
      return Ember.A([]);
    }),

    /**
     * List of columns shown in the table. It's created from the {{#crossLink 'Components.ModelsTable/columns:property'}}columns{{/crossLink}} provided to the component
     *
     * @type Object[]
     * @property processedColumns
     * @default []
     * @private
     */
    processedColumns: Ember.computed(function () {
      return Ember.A([]);
    }),

    /**
     * List of the additional headers. Used to group columns.
     *
     * Each object may have such fields:
     *
     * * `title` (string) - Header for grouped column
     * * `colspan` (number) - HTML colspan attr
     * * `rowspan` (number) - HTML rowspan attr
     *
     * @property groupedHeaders
     * @type groupedHeader[][]
     * @default []
     */
    groupedHeaders: Ember.computed(function () {
      return Ember.A([]);
    }),

    /**
     * Determines if page size should be shown
     *
     * @type boolean
     * @property showPageSize
     * @default true
     */
    showPageSize: true,

    /**
     * Expanded row items.
     *
     * It's set to the initial value when current page or page size is changed
     *
     * @type object[]
     * @property expandedItems
     * @default null
     */
    expandedItems: Ember.computed({
      get: function get() {
        return Ember.A([]);
      },
      set: function set(k, v) {
        if (!Ember.isArray(v)) {
          true && Ember.warn('`expandedItems` must be an array.', false, { id: '#emt-expandedItems-array' });
        }
        return Ember.A(v);
      }
    }),

    /**
     * true - allow to expand more than 1 row,
     * false - only 1 row may be expanded in the same time
     *
     * @type boolean
     * @property multipleExpand
     * @default false
     */
    multipleExpand: false,

    /**
     * List of grouped property values where the groups are collapsed
     *
     * @type array
     * @property collapsedGroupValues
     * @default []
     */
    collapsedGroupValues: Ember.computed({
      get: function get() {
        return Ember.A([]);
      },
      set: function set(k, v) {
        if (!Ember.isArray(v)) {
          true && Ember.warn('`collapsedGroupValues` must be an array.', false, { id: '#emt-collapsedGroupValues-array' });
        }
        return Ember.A(v);
      }
    }),

    /**
     * Allow or disallow to select rows on click.
     * If `false` - no row can be selected
     *
     * @type boolean
     * @property selectRowOnClick
     * @default true
     */
    selectRowOnClick: true,

    /**
     * Allow or disallow to select multiple rows.
     * If `false` - only one row may be selected in the same time
     *
     * @type boolean
     * @property multipleSelect
     * @default false
     */
    multipleSelect: false,

    /**
     * Component used in the 'expanded' row
     *
     * It will receive several options:
     * * `record` - current row value
     * * `processedColumns` - current column (one of the {{#crossLink 'Components.ModelsTable/processedColumns:property'}}processedColumns{{/crossLink}})
     * * `index` - current row index
     * * `selectedItems` - bound from {{#crossLink 'Components.ModelsTable/selectedItems:property'}}selectedItems{{/crossLink}}
     * * `visibleProcessedColumns` - bound from {{#crossLink 'Components.ModelsTable/visibleProcessedColumns:property'}}visibleProcessedColumns{{/crossLink}}
     * * `clickOnRow` - closure action {{#crossLink 'Components.ModelsTable/actions.clickOnRow:method'}}ModelsTable.actions.clickOnRow{{/crossLink}}
     * * `sendAction` - closure action {{#crossLink 'Components.ModelsTable/actions.sendAction:method'}}ModelsTable.actions.sendAction{{/crossLink}}
     * * `themeInstance` - bound from {{#crossLink 'Components.ModelsTable/themeInstance:property'}}themeInstance{{/crossLink}}
     *
     * Usage:
     *
     * ```hbs
     * {{models-table data=model columns=columns expandedRowComponent=(component "expanded-row")}}
     * ```
     *
     * @type object
     * @property expandedRowComponent
     * @default null
     */
    expandedRowComponent: null,

    /**
     * Component used in the row with a grouped value
     *
     * This component won't be used if {{#crossLink 'Component.ModelsTable/useDataGrouping:property'}}useDataGrouping{{/crossLink}} is not `true`
     *
     * Component will receive several options:
     *
     * * `groupedValue` - grouped property value
     * * `currentGroupingPropertyName` - bound from {{#crossLink 'Components.ModelsTable/currentGroupingPropertyName:property'}}currentGroupingPropertyName{{/crossLink}}
     * * `displayGroupedValueAs` - bound from {{#crossLink 'Components.ModelsTable/displayGroupedValueAs:property'}}ModelsTable.displayGroupedValueAs{{/crossLink}}
     * * `toggleGroupedRows` - closure action {{#crossLink 'Components.ModelsTable/actions.toggleGroupedRows:method'}}ModelsTable.actions.toggleGroupedRows{{/crossLink}}
     * * `toggleGroupedRowsExpands` - closure action {{#crossLink 'Components.ModelsTable/actions.toggleGroupedRowsExpands:method'}}ModelsTable.actions.toggleGroupedRowsExpands{{/crossLink}}
     * * `toggleGroupedRowsSelection` - closure action {{#crossLink 'Components.ModelsTable/actions.toggleGroupedRowsSelection:method'}}ModelsTable.actions.toggleGroupedRowsSelection{{/crossLink}}
     * * `visibleProcessedColumns` - bound from {{#crossLink 'Components.ModelsTable/visibleProcessedColumns:property'}}ModelsTable.visibleProcessedColumns{{/crossLink}}
     * * `themeInstance` - bound from {{#crossLink 'Components.ModelsTable/themeInstance:property'}}ModelsTable.themeInstance{{/crossLink}}
     * * `sendAction` - closure action {{#crossLink 'Components.ModelsTable/actions.sendAction:method'}}ModelsTable.actions.sendAction{{/crossLink}}
     * * `groupedItems` - list of all rows group items
     * * `visibleGroupedItems` - list of rows group items shown on the current table page
     * * `selectedGroupedItems` - list of selected rows group items
     * * `expandedGroupedItems` - list of expanded rows group items
     *
     * Usage:
     *
     * ```hbs
     * {{models-table data=model columns=columns groupingRowComponent=(component "grouping-row")}}
     * ```
     *
     * @type object
     * @property groupingRowComponent
     * @default null
     */
    groupingRowComponent: null,

    /**
     * This component won't be used if {{#crossLink 'Component.ModelsTable/useDataGrouping:property'}}useDataGrouping{{/crossLink}} is not `true`
     *
     * Component will receive several options:
     *
     * * `visibleProcessedColumns` - bound from {{#crossLink 'Components.ModelsTable/visibleProcessedColumns:property'}}ModelsTable.visibleProcessedColumns{{/crossLink}}
     * * `themeInstance` - bound from {{#crossLink 'Components.ModelsTable/themeInstance:property'}}ModelsTable.themeInstance{{/crossLink}}
     * * `sendAction` - closure action {{#crossLink 'Components.ModelsTable/actions.sendAction:method'}}ModelsTable.actions.sendAction{{/crossLink}}
     * * `groupedItems` - list of all rows group items
     * * `visibleGroupedItems` - list of rows group items shown on the current table page
     * * `selectedGroupedItems` - list of selected rows group items
     * * `expandedGroupedItems` - list of expanded rows group items
     *
     * Usage:
     *
     * ```hbs
     * {{models-table data=model columns=columns groupSummaryRowComponent=(component "group-summary-row")}}
     * ```
     *
     * @type object
     * @property groupSummaryRowComponent
     * @default null
     */
    groupSummaryRowComponent: null,

    /**
     * Closure action sent on user interaction
     *
     * Action will send one parameter - object with fields:
     *
     * * `sort` - list with sort value `propertyName:sortDirection`
     * * `currentPageNumber` - currently shown page number
     * * `pageSize` - current page size
     * * `filterString` - global filter value
     * * `filteredContent` - filtered data
     * * `selectedItems` - list with selected row items
     * * `expandedItems` - list with expanded row items
     * * `columnFilters` - hash with fields equal to filtered propertyName and values equal to filter values
     *
     * Usage:
     *
     * ```hbs
     * {{models-table data=model columns=columns displayDataChangedAction=(action "someAction")}}
     * ```
     *
     * @event displayDataChangedAction
     */
    displayDataChangedAction: null,

    /**
     * Action sent on init to give access to the Public API
     *
     * @default null
     * @property registerAPI
     * @type closureFunction
     */
    registerAPI: null,

    /**
     * Closure action sent on change of visible columns
     *
     * The action will receive an array of objects as parameter, where every object looks like this: `{ propertyName: 'firstName', isHidden: true, mayBeHidden: false }`
     *
     * * Usage:
     *
     * ```hbs
     * {{models-table data=model columns=columns columnsVisibilityChangedAction=(action "someAction")}}
     * ```
     *
     * @event columnsVisibilityChangedAction
     */
    columnsVisibilityChangedAction: null,

    /**
     * Closure action sent on row double-click
     *
     * Usage
     *
     * ```hbs
     * {{models-table data=model columns=columns rowDoubleClickAction=(action "someAction")}}
     * ```
     *
     * @event rowDoubleClickAction
     */
    rowDoubleClickAction: null,

    /**
     * Closure action sent on row hover
     *
     * Usage
     *
     * ```hbs
     * {{models-table data=model columns=columns rowHoverAction=(action "someAction")}}
     * ```
     *
     * @event rowHoverAction
     */
    rowHoverAction: null,

    /**
     * Closure action sent on row out
     *
     * Usage
     *
     * ```hbs
     * {{models-table data=model columns=columns rowOutAction=(action "someAction")}}
     * ```
     *
     * @event rowOutAction
     */
    rowOutAction: null,

    /**
     * List of currently selected row items
     *
     * Row may be selected by clicking on it, if {{#crossLink 'Components.ModelsTable/selectRowOnClick:property'}}selectRowOnClick{{/crossLink}} is set to `true`
     *
     * @default null
     * @property selectedItems
     * @type object[]
     */
    selectedItems: Ember.computed({
      get: function get() {
        return Ember.A([]);
      },
      set: function set(k, v) {
        if (!Ember.isArray(v)) {
          true && Ember.warn('`selectedItems` must be an array.', false, { id: '#emt-selectedItems-array' });
        }
        return Ember.A(v);
      }
    }),

    /**
     * List of the currently visible columns
     *
     * @type Object[]
     * @property visibleProcessedColumns
     * @default []
     * @private
     */
    visibleProcessedColumns: Ember.computed.filterBy('processedColumns', 'isVisible', true),

    /**
     * True if all processedColumns are hidden by <code>isHidden</code>
     *
     * @type boolean
     * @property allColumnsAreHidden
     * @readonly
     * @private
     */
    allColumnsAreHidden: Ember.computed('processedColumns.@each.isHidden', function () {
      var processedColumns = Ember.get(this, 'processedColumns');
      return Ember.get(processedColumns, 'length') > 0 && processedColumns.isEvery('isHidden', true);
    }).readOnly(),

    /**
     * List of property names can be used for grouping
     *
     * It may be a list of strings of list of objects. In first case label and value in the select-box will be the same.
     * In the second case you must set `label` and `value` properties for each list item
     *
     * **IMPORTANT** It must contain {{#crossLink 'Components.ModelsTable/currentGroupingPropertyName:property'}}currentGroupingPropertyName{{/crossLink}}-value
     *
     * @property dataGroupProperties
     * @type string[]|object[]
     * @default []
     */
    dataGroupProperties: Ember.computed(function () {
      return Ember.A([]);
    }),

    /**
     * @property dataGroupOptions
     * @type object[]
     * @private
     * @readonly
     */
    dataGroupOptions: Ember.computed('dataGroupProperties.[]', function () {
      return Ember.get(this, 'dataGroupProperties').map(function (item) {
        return 'object' === Ember.typeOf(item) || 'instance' === Ember.typeOf(item) ? item : { label: propertyNameToTitle(item), value: item };
      });
    }).readOnly(),

    /**
     * `true` if some value is set to the global filter
     *
     * @type boolean
     * @property globalFilterUsed
     * @readonly
     * @private
     */
    globalFilterUsed: Ember.computed.notEmpty('filterString'),

    /**
     * `true` if global filter or filter by any column is used
     *
     * @type boolean
     * @property anyFilterUsed
     * @readonly
     * @private
     */
    anyFilterUsed: Ember.computed('globalFilterUsed', 'processedColumns.@each.filterUsed', function () {
      return Ember.get(this, 'globalFilterUsed') || Ember.get(this, 'processedColumns').isAny('filterUsed');
    }).readOnly(),

    /**
     * `true` if all processedColumns don't use filtering and sorting
     *
     * @type boolean
     * @property noHeaderFilteringAndSorting
     * @readonly
     * @private
     */
    noHeaderFilteringAndSorting: Ember.computed('processedColumns.@each.{useSorting,useFilter}', function () {
      var processedColumns = Ember.get(this, 'processedColumns');
      return processedColumns.isEvery('useFilter', false) && processedColumns.isEvery('useSorting', false);
    }).readOnly(),

    /**
     * Number of pages
     *
     * @type number
     * @property pagesCount
     * @readonly
     * @private
     */
    pagesCount: Ember.computed('arrangedContent.[]', 'pageSize', function () {
      var pagesCount = Ember.get(this, 'arrangedContent.length') / parseInt(Ember.get(this, 'pageSize'), 10);
      return 0 === pagesCount % 1 ? pagesCount : Math.floor(pagesCount) + 1;
    }).readOnly(),

    /**
     * {{#crossLink 'Components.ModelsTable/data:property'}}data{{/crossLink}} filtered with a global filter and columns filters
     *
     * Filtering by columns is ignored if {{#crossLink 'Components.ModelsTable/useFilteringByColumns:property'}}useFilteringByColumns{{/crossLink}} is set to `false`
     *
     * @type Object[]
     * @property filteredContent
     * @readonly
     * @private
     */
    filteredContent: Ember.computed('filterString', 'data.[]', 'useFilteringByColumns', 'processedColumns.@each.filterString', function () {
      var processedColumns = Ember.get(this, 'processedColumns');
      var data = Ember.get(this, 'data');
      var useFilteringByColumns = Ember.get(this, 'useFilteringByColumns');
      var filteringIgnoreCase = Ember.get(this, 'filteringIgnoreCase');
      var doFilteringByHiddenColumns = Ember.get(this, 'doFilteringByHiddenColumns');
      if (!Ember.isArray(data)) {
        return [];
      }
      if (!Ember.get(this, 'anyFilterUsed')) {
        return data.slice();
      }
      var filterString = Ember.get(this, 'filterString');
      if (filteringIgnoreCase) {
        filterString = filterString.toLowerCase();
      }

      var _processedColumns = Ember.A(processedColumns.filterBy('useFilter'));
      if (!doFilteringByHiddenColumns) {
        _processedColumns = Ember.A(_processedColumns.filterBy('isHidden', false));
      }
      if (!Ember.get(_processedColumns, 'length')) {
        return data.slice();
      }

      // global search
      var filtersFor = Ember.A(Ember.A(_processedColumns.mapBy('filterField')).compact());
      var globalSearch = data.filter(function (row) {
        return filtersFor.any(function (filterFor) {
          var cellValue = '' + Ember.get(row, filterFor);
          if (filteringIgnoreCase) {
            cellValue = cellValue.toLowerCase();
          }
          return -1 !== cellValue.indexOf(filterString);
        });
      });

      if (!useFilteringByColumns) {
        return globalSearch;
      }

      // search by each column
      _processedColumns = _processedColumns.filterBy('filterField').filter(function (c) {
        return !(Ember.get(c, 'filterWithSelect') && '' === Ember.get(c, 'filterString'));
      });
      return globalSearch.filter(function (row) {
        return _processedColumns.every(function (c) {
          var filterFor = Ember.get(c, 'filterField');
          var cellValue = '' + Ember.get(row, filterFor);
          var filterString = Ember.get(c, 'filterString');
          if (filteringIgnoreCase) {
            cellValue = Ember.typeOf(cellValue) === 'string' ? cellValue.toLowerCase() : cellValue;
            filterString = Ember.typeOf(filterString) === 'string' ? filterString.toLowerCase() : filterString;
          }
          return 'function' === Ember.typeOf(c.filterFunction) ? c.filterFunction(cellValue, filterString, row) : 0 === Ember.compare(cellValue, filterString);
        });
      });
    }),

    /**
     * {{#crossLink 'Components.ModelsTable/filteredContent:property'}}filteredContent{{/crossLink}} sorted by needed properties
     *
     * @type Object[]
     * @property arrangedContent
     * @readonly
     * @private
     */
    arrangedContent: Ember.computed('filteredContent.[]', 'sortProperties.[]', function () {
      var filteredContent = Ember.get(this, 'filteredContent');
      var sortProperties = Ember.get(this, 'sortProperties').map(function (p) {
        var _p$split = p.split(':'),
            _p$split2 = _slicedToArray(_p$split, 2),
            prop = _p$split2[0],
            direction = _p$split2[1];

        direction = direction || 'asc';

        return [prop, direction];
      });

      var _filteredContent = filteredContent.slice();
      var sortedPropsLength = Ember.get(sortProperties, 'length');
      return sortedPropsLength ? _filteredContent.sort(function (row1, row2) {
        for (var i = 0; i < sortedPropsLength; i++) {
          var _sortProperties$i = _slicedToArray(sortProperties[i], 2),
              prop = _sortProperties$i[0],
              direction = _sortProperties$i[1];

          var result = prop ? (0, _emberModelsTableUtilsBetterCompare["default"])(Ember.get(row1, prop), Ember.get(row2, prop)) : 0;
          if (result !== 0) {
            return direction === 'desc' ? -1 * result : result;
          }
        }

        return 0;
      }) : _filteredContent;
    }),

    filteredContentObserver: function filteredContentObserver() {
      Ember.run.once(this, this.filteredContentObserverOnce);
    },
    filteredContentObserverOnce: function filteredContentObserverOnce() {
      this.updateState({ recordsCount: this.get('filteredContent.length') });
    },

    /**
     * {{#crossLink 'Components.ModelsTable/filteredContent:property'}}filteredContent{{/crossLink}} grouped by {{#crossLink 'Components.ModelsTable/currentGroupingPropertyName:property'}}currentGroupingPropertyName{{/crossLink}} sorted by needed properties
     *
     * @property groupedArrangedContent
     * @type object[]
     * @private
     * @readonly
     */
    groupedArrangedContent: Ember.computed('filteredContent.[]', 'sortProperties.[]', 'useDataGrouping', 'currentGroupingPropertyName', 'sortByGroupedFieldDirection', function () {
      var useDataGrouping = Ember.get(this, 'useDataGrouping');
      var currentGroupingPropertyName = Ember.get(this, 'currentGroupingPropertyName');
      var filteredContent = Ember.get(this, 'filteredContent');
      var sortByGroupedFieldDirection = Ember.get(this, 'sortByGroupedFieldDirection');
      var grouped = {};
      if (!useDataGrouping || !Ember.isArray(filteredContent)) {
        return grouped;
      }
      var sortProperties = Ember.get(this, 'sortProperties').map(function (p) {
        var _p$split3 = p.split(':'),
            _p$split4 = _slicedToArray(_p$split3, 2),
            prop = _p$split4[0],
            direction = _p$split4[1];

        direction = direction || 'asc';
        return [prop, direction];
      });

      grouped = chunkBy(filteredContent, currentGroupingPropertyName, sortByGroupedFieldDirection);

      var sortPropsLength = Ember.get(sortProperties, 'length');
      grouped = grouped.map(function (group) {
        return sortPropsLength ? Ember.A(group.sort(function (row1, row2) {
          for (var i = 0; i < sortPropsLength; i++) {
            var _sortProperties$i2 = _slicedToArray(sortProperties[i], 2),
                prop = _sortProperties$i2[0],
                direction = _sortProperties$i2[1];

            var result = prop ? (0, _emberModelsTableUtilsBetterCompare["default"])(Ember.get(row1, prop), Ember.get(row2, prop)) : 0;
            if (result !== 0) {
              return direction === 'desc' ? -1 * result : result;
            }
          }
          return 0;
        })) : group;
      });
      return grouped.reduce(function (result, group) {
        return Ember.A([].concat(_toConsumableArray(result), _toConsumableArray(group)));
      }, []);
      /*return keys(grouped).sort((v1, v2) => {
        let result = betterCompare(v1, v2);
        if (result !== 0) {
          return (sortByGroupedFieldDirection === 'desc') ? (-1 * result) : result;
        }
        return 0;
      }).reduce((result, key) => A([...result, ...grouped[key]]), A([]));*/
    }),

    /**
     * Content of the current table page
     *
     * {{#crossLink 'Components.ModelsTable/arrangedContent:property'}}arrangedContent{{/crossLink}} sliced for currently shown page
     *
     * @type Object[]
     * @property visibleContent
     * @readonly
     * @private
     */
    visibleContent: Ember.computed('arrangedContent.[]', 'pageSize', 'currentPageNumber', function () {
      var arrangedContent = Ember.get(this, 'arrangedContent');
      var pageSize = parseInt(Ember.get(this, 'pageSize'), 10);
      var currentPageNumber = Ember.get(this, 'currentPageNumber');
      var startIndex = pageSize * (currentPageNumber - 1);
      if (Ember.get(arrangedContent, 'length') < pageSize) {
        return arrangedContent;
      }
      return arrangedContent.slice(startIndex, startIndex + pageSize);
    }),

    /**
     * Content of the current table page when rows grouping is used
     *
     * {{#crossLink 'Components.ModelsTable/groupedVisibleContent:property'}}groupedVisibleContent{{/crossLink}} sliced for currently shown page
     *
     * @property groupedVisibleContent
     * @default {}
     * @type object
     * @private
     * @readonly
     */
    groupedVisibleContent: Ember.computed('groupedArrangedContent', 'pageSize', 'currentPageNumber', 'useDataGrouping', 'currentGroupingPropertyName', function () {
      var useDataGrouping = Ember.get(this, 'useDataGrouping');
      var currentGroupingPropertyName = Ember.get(this, 'currentGroupingPropertyName');
      var groupedArrangedContent = Ember.get(this, 'groupedArrangedContent');
      var pageSize = parseInt(Ember.get(this, 'pageSize'), 10);
      var currentPageNumber = Ember.get(this, 'currentPageNumber');
      if (!useDataGrouping) {
        return [];
      }
      var startIndex = pageSize * (currentPageNumber - 1);
      return Ember.get(groupedArrangedContent, 'length') < pageSize ? chunkBy(groupedArrangedContent, currentGroupingPropertyName) : chunkBy(groupedArrangedContent.slice(startIndex, startIndex + pageSize), currentGroupingPropertyName);
    }),

    /**
     * List of grouped property values in order to show groups in the table
     *
     * @type array
     * @property groupedVisibleContentValuesOrder
     * @private
     * @readonly
     */
    groupedVisibleContentValuesOrder: Ember.computed('groupedVisibleContent.[]', 'currentGroupingPropertyName', function () {
      var currentGroupingPropertyName = Ember.get(this, 'currentGroupingPropertyName');
      return Ember.get(this, 'groupedVisibleContent').map(function (group) {
        return Ember.get(group, 'firstObject.' + currentGroupingPropertyName);
      });
    }),

    /**
     * Is user on the last page
     *
     * @type boolean
     * @property isLastPage
     * @readonly
     * @private
     */
    isLastPage: Ember.computed('currentPageNumber', 'pagesCount', function () {
      return Ember.get(this, 'currentPageNumber') >= Ember.get(this, 'pagesCount');
    }).readOnly(),

    /**
     * Alias to <code>arrangedContent.length</code>
     *
     * @type number
     * @property arrangedContentLength
     * @readonly
     * @private
     */
    arrangedContentLength: Ember.computed.alias('arrangedContent.length'),

    /**
     * Index of the first currently shown record
     *
     * @type number
     * @property firstIndex
     * @private
     * @readonly
     */
    firstIndex: Ember.computed('arrangedContentLength', 'pageSize', 'currentPageNumber', function () {
      return 0 === Ember.get(this, 'arrangedContentLength') ? 0 : parseInt(Ember.get(this, 'pageSize'), 10) * (Ember.get(this, 'currentPageNumber') - 1) + 1;
    }).readOnly(),

    /**
     * Index of the last currently shown record
     *
     * @type number
     * @property lastIndex
     * @readonly
     * @private
     */
    lastIndex: Ember.computed('isLastPage', 'arrangedContentLength', 'currentPageNumber', 'pageSize', function () {
      return Ember.get(this, 'isLastPage') ? Ember.get(this, 'arrangedContentLength') : Ember.get(this, 'currentPageNumber') * parseInt(Ember.get(this, 'pageSize'), 10);
    }).readOnly(),

    /**
     * List of possible <code>pageSize</code> values. Used to change size of <code>visibleContent</code>
     *
     * @type number[]
     * @default [10, 25, 50]
     * @property pageSizeValues
     */
    pageSizeValues: Ember.computed(function () {
      return Ember.A([10, 25, 50]);
    }),

    /**
     * List of options for pageSize-selectBox
     * It's mapped from <code>pageSizeValues</code>
     * This value should not be set manually!
     *
     * @type {value: string|number, label: string|number}
     * @property pageSizeOptions
     * @default []
     * @private
     */
    pageSizeOptions: Ember.computed(function () {
      return Ember.A([]);
    }),

    /**
     * These are options for the columns dropdown.
     * By default, the 'Show All', 'Hide All' and 'Restore Defaults' buttons are displayed.
     *
     * @type { showAll: boolean, hideAll: boolean, restoreDefaults: boolean, columnSets: object[] }
     * @property columnDropdownOptions
     * @readonly
     * @private
     */
    columnDropdownOptions: Ember.computed('columnSets.{label,showColumns,hideOtherColumns}', function () {
      return Ember.Object.create({
        showAll: true,
        hideAll: true,
        restoreDefaults: true,
        columnSets: Ember.A(Ember.get(this, 'columnSets') || [])
      });
    }),

    /**
     * Public API that allows for programmatic interaction with the component
     *
     * {
     *  refilter() - Invalidates the filteredContent property, causing the table to be re-filtered.
     *  recordsCount - Size of the current arranged content
     * }
     *
     * @type object
     * @property publicAPI
     *
     */
    publicAPI: null,

    updateState: function updateState(changes) {
      var newState = Ember.set(this, 'publicAPI', Ember.assign({}, this.get('publicAPI'), changes));
      var registerAPI = this.get('registerAPI');
      if (registerAPI) {
        registerAPI(newState);
      }
      return newState;
    },

    /**
     * Show first page if for some reasons there is no content for current page, but table data exists
     *
     * @method visibleContentObserver
     * @returns {undefined}
     * @private
     */
    visibleContentObserver: function visibleContentObserver() {
      Ember.run.once(this, this.visibleContentObserverOnce);
    },

    /**
     * @method visibleContentObserverOnce
     * @returns {undefined}
     * @private
     */
    visibleContentObserverOnce: function visibleContentObserverOnce() {
      var visibleContentLength = Ember.get(this, 'visibleContent.length');
      var dataLength = Ember.get(this, 'data.length');
      var currentPageNumber = Ember.get(this, 'currentPageNumber');
      if (!visibleContentLength && dataLength && currentPageNumber !== 1) {
        Ember.set(this, 'currentPageNumber', 1);
      }
    },
    init: function init() {
      this._super.apply(this, arguments);
      this.setup();
    },
    didReceiveAttrs: function didReceiveAttrs() {
      this.updateColumns();
    },
    didInsertElement: function didInsertElement() {
      this.focus();
    },

    /**
     * Component init
     *
     * Set visibility and filtering attributes for each column
     *
     * @method setup
     * @returns {undefined}
     */
    setup: function setup() {
      var _this = this;

      this._setupSelectedRows();
      this._setupColumns();
      this._setupPageSizeOptions();

      if (Ember.get(this, 'columnsAreUpdateable')) {
        var columnFieldsToCheckUpdate = Ember.get(this, 'columnFieldsToCheckUpdate');
        true && !('array' === Ember.typeOf(columnFieldsToCheckUpdate)) && Ember.assert('`columnFieldsToCheckUpdate` should be an array of strings', 'array' === Ember.typeOf(columnFieldsToCheckUpdate));

        columnFieldsToCheckUpdate.forEach(function (propertyName) {
          return _this.addObserver('columns.@each.' + propertyName, _this, _this._setupColumnsOnce);
        });
      }
      this.addObserver('visibleContent.length', this, this.visibleContentObserver);
      this.addObserver('filteredContent.length', this, this.filteredContentObserver);

      Ember.set(this, 'publicAPI', {});

      this.updateState({
        recordsCount: this.get('filteredContent.length') || 0,
        refilter: this.refilter.bind(this)
      });
    },
    refilter: function refilter() {
      this.notifyPropertyChange('filteredContent');
    },

    /**
     * Recalculate processedColumns when the columns attr changes
     *
     * @method updateColumns
     * @returns {undefined}
     */
    updateColumns: function updateColumns() {
      if (Ember.get(this, 'columnsAreUpdateable')) {
        this._setupColumns();
      }
    },

    /**
     * Focus on 'Global filter' on component render
     *
     * @method focus
     * @returns {undefined}
     */
    focus: function focus() {
      if (Ember.get(this, 'showGlobalFilter') && Ember.get(this, 'focusGlobalFilter')) {
        Ember.$('.filterString').focus();
      }
    },

    /**
     * Preselect table rows if `selectedItems` is provided
     *
     * `multipleSelected` may be set `true` if `selectedItems` has more than 1 item
     *
     * @private _setupSelectedRows
     * @returns {undefined}
     * @method
     */
    _setupSelectedRows: function _setupSelectedRows() {
      var selectedItems = Ember.get(this, 'selectedItems');
      if (Ember.isArray(selectedItems) && Ember.get(selectedItems, 'length') > 1 && !Ember.get(this, 'multipleSelected')) {
        true && Ember.warn('`multipleSelected` is set `true`, because you have provided multiple `selectedItems`.', false, { id: '#emt-multipleSelected_autoset' });

        Ember.set(this, 'multipleSelected', true);
      }
    },

    /**
     * Wrapper for <code>_setupColumns</code> to call it only once when observer is fired
     *
     * @method _setupColumnsOnce
     * @returns {undefined}
     * @private
     */
    _setupColumnsOnce: function _setupColumnsOnce() {
      Ember.run.once(this, this._setupColumns);
    },

    /**
     * Generate hash for column-`extend`
     *
     * @method _createColumnHash
     * @param {object} options
     * @returns {object}
     * @private
     */
    _createColumnHash: function _createColumnHash(options) {
      var hash = {
        __mt: this,
        data: Ember.computed.readOnly('__mt.data')
      };
      var propertyName = options.propertyName;

      if (Ember.get(options, 'filterWithSelect') && (Ember.get(options, 'filteredBy') || Ember.get(options, 'propertyName')) && !Ember.get(options, 'disableFiltering')) {
        var predefinedFilterOptions = Ember.get(options, 'predefinedFilterOptions');
        var usePredefinedFilterOptions = 'array' === Ember.typeOf(predefinedFilterOptions);
        if (usePredefinedFilterOptions && Ember.get(predefinedFilterOptions, 'length')) {
          var types = Ember.A(['object', 'instance']);
          var allObjects = Ember.A(predefinedFilterOptions).every(function (option) {
            return types.includes(Ember.typeOf(option)) && option.hasOwnProperty('label') && option.hasOwnProperty('value');
          });
          var allPrimitives = Ember.A(predefinedFilterOptions).every(function (option) {
            return !types.includes(Ember.typeOf(option));
          });
          true && !(allObjects || allPrimitives) && Ember.assert('`predefinedFilterOptions` should be an array of objects or primitives and not mixed', allObjects || allPrimitives);

          if (allPrimitives) {
            predefinedFilterOptions = predefinedFilterOptions.map(optionStrToObj);
          }
          if ('' !== predefinedFilterOptions[0].value) {
            predefinedFilterOptions = [{ value: '', label: '' }].concat(_toConsumableArray(predefinedFilterOptions));
          }
          hash.filterOptions = usePredefinedFilterOptions ? Ember.A(predefinedFilterOptions) : [];
        } else if (usePredefinedFilterOptions) {
          // Empty array as predefined filter
          hash.useFilter = false;
        } else {
          if (propertyName) {
            hash.filterOptions = getFilterOptionsCP(propertyName);
          }
        }
      }
      return hash;
    },

    /**
     * Set values for some column-properties after its creation
     *
     * @method _postProcessColumn
     * @param {object} column
     * @returns {object}
     * @private
     */
    _postProcessColumn: function _postProcessColumn(column) {
      var filterOptions = Ember.get(column, 'filterOptions');
      var placeholder = Ember.get(column, 'filterPlaceholder');
      if (Ember.isArray(filterOptions) && placeholder && !filterOptions[0].label) {
        Ember.set(column, 'filterOptions.firstObject.label', placeholder);
      }
      return column;
    },

    /**
     * Create a column.
     * This can be overwritten if you need to use your own column object.
     *
     * Override must something like:
     *
     * ```js
     * _createColumn(options) {
     *   const hash = this._createColumnHash(options);
     *   const column = ModelsTableColumn.extend(hash).create(options);
     *   return this._postProcessColumn(column);
     * }
     * ```
     *
     * @method _createColumn
     * @param {object} options
     * @returns {Object}
     */
    _createColumn: function _createColumn(options) {
      var hash = this._createColumnHash(options);
      var column = _emberModelsTableUtilsColumn["default"].extend(hash).create(options);
      return this._postProcessColumn(column);
    },

    /**
     * Create new properties for `columns`
     *
     * @method _setupColumns
     * @returns {undefined}
     * @private
     */
    _setupColumns: function _setupColumns() {
      var _this2 = this;

      var self = this;

      var nColumns = Ember.A(Ember.get(this, 'columns').map(function (column) {
        var filterFunction = Ember.get(column, 'filterFunction');
        filterFunction = 'function' === Ember.typeOf(filterFunction) ? filterFunction : defaultFilter;

        var c = _this2._createColumn(column);

        ['colspanForSortCell', 'colspanForFilterCell'].forEach(function (prop) {
          var val = Ember.get(c, prop);
          true && !(Ember.typeOf(val) === 'number' && val >= 1) && Ember.assert('"' + prop + '" must be 1 or greater. You passed "' + val + '"', Ember.typeOf(val) === 'number' && val >= 1);
        });

        Ember.setProperties(c, {
          filterString: Ember.get(c, 'filterString') || '',
          originalDefinition: column
        });

        _this2._setupColumnsComponent(c, column);

        Ember.set(c, 'filterFunction', filterFunction);

        if (Ember.isNone(Ember.get(c, 'mayBeHidden'))) {
          Ember.set(c, 'mayBeHidden', true);
        }

        var sortDirection = column.sortDirection,
            sortPrecedence = column.sortPrecedence;

        var hasSortPrecedence = !Ember.isNone(sortPrecedence) && sortPrecedence > NOT_SORTED;
        var defaultSortPrecedence = hasSortPrecedence ? sortPrecedence : NOT_SORTED;
        var defaultSorting = sortDirection && sortPrecedence > NOT_SORTED ? sortDirection.toLowerCase() : 'none';

        Ember.setProperties(c, {
          defaultVisible: !Ember.get(c, 'isHidden'),
          sorting: defaultSorting,
          sortPrecedence: defaultSortPrecedence
        });
        return c;
      }));
      nColumns.filterBy('propertyName').forEach(function (column) {
        var propertyName = Ember.get(column, 'propertyName');
        if (Ember.isNone(Ember.get(column, 'title'))) {
          Ember.set(column, 'title', propertyNameToTitle(propertyName));
        }
      });
      Ember.set(this, 'processedColumns', nColumns);

      // Apply initial sorting
      Ember.set(this, 'sortProperties', Ember.A());
      var filteredOrderedColumns = nColumns.sortBy('sortPrecedence').filter(function (col) {
        return isSortedByDefault(col);
      });
      filteredOrderedColumns.forEach(function (column) {
        self.send('sort', column);
        var defaultSortedBy = column.sortedBy || column.propertyName;
        var sortingArgs = [column, defaultSortedBy, column.sortDirection.toLowerCase()];
        if (Ember.get(_this2, 'multipleColumnsSorting')) {
          _this2._multiColumnsSorting.apply(_this2, sortingArgs);
        } else {
          _this2._singleColumnSorting.apply(_this2, sortingArgs);
        }
      });
      this.updateHeaderCellsColspanOnce();
    },

    /**
     * Create new properties for `columns` for components
     *
     * @method _setupColumnsComponent
     * @param {ModelsTableColumn} c
     * @param {object} column raw column
     * @returns {undefined}
     * @private
     */
    _setupColumnsComponent: function _setupColumnsComponent(c, column) {
      var columnComponents = Ember.get(this, 'columnComponents');
      if (Ember.isPresent(columnComponents)) {

        // display component
        var componentName = Ember.get(column, 'component');
        if (Ember.isPresent(componentName)) {
          var hashComponent = Ember.get(columnComponents, componentName);
          if (Ember.isPresent(hashComponent)) {
            Ember.set(c, 'component', hashComponent);
          }
        }

        // edit component
        componentName = Ember.get(column, 'componentForEdit');
        if (Ember.isPresent(componentName)) {
          var _hashComponent = Ember.get(columnComponents, componentName);
          if (Ember.isPresent(_hashComponent)) {
            Ember.set(c, 'componentForEdit', _hashComponent);
          }
        }
      }
    },

    /**
     * Provide backward compatibility with <code>pageSizeValues</code> equal to an array with numbers and not objects
     * <code>pageSizeValues</code> is live as is, <code>pageSizeOptions</code> is used in the templates
     *
     * @method _setupPageSizeOptions
     * @returns {undefined}
     * @private
     */
    _setupPageSizeOptions: function _setupPageSizeOptions() {
      var pageSizeOptions = Ember.get(this, 'pageSizeValues').map(optionStrToObj);
      Ember.set(this, 'pageSizeOptions', pageSizeOptions);
    },

    /**
     * Set <code>sortProperties</code> when single-column sorting is used
     *
     * @param {ModelsTableColumn} column
     * @param {string} sortedBy
     * @param {string} newSorting 'asc|desc|none'
     * @method _singleColumnSorting
     * @returns {undefined}
     * @private
     */
    _singleColumnSorting: function _singleColumnSorting(column, sortedBy, newSorting) {
      Ember.get(this, 'processedColumns').setEach('sorting', 'none');
      Ember.set(column, 'sorting', newSorting);
      Ember.set(this, 'sortProperties', 'none' === newSorting ? [] : [sortedBy + ':' + newSorting]);
    },

    /**
     * Set <code>sortProperties</code> when multi-columns sorting is used
     *
     * @param {ModelsTableColumn} column
     * @param {string} sortedBy
     * @param {string} newSorting 'asc|desc|none'
     * @method _multiColumnsSorting
     * @returns {undefined}
     * @private
     */
    _multiColumnsSorting: function _multiColumnsSorting(column, sortedBy, newSorting) {
      Ember.set(column, 'sorting', newSorting);
      var sortProperties = Ember.get(this, 'sortProperties');
      var sortPropertiesMap = {};
      sortProperties.forEach(function (p) {
        var _p$split5 = p.split(':'),
            _p$split6 = _slicedToArray(_p$split5, 2),
            propertyName = _p$split6[0],
            order = _p$split6[1];

        sortPropertiesMap[propertyName] = order;
      });
      delete sortPropertiesMap[sortedBy];

      var newSortProperties = Ember.A([]);
      keys(sortPropertiesMap).forEach(function (propertyName) {
        if (propertyName !== sortedBy) {
          newSortProperties.pushObject(propertyName + ':' + sortPropertiesMap[propertyName]);
        }
      });
      if ('none' !== newSorting) {
        newSortProperties.pushObject(sortedBy + ':' + newSorting);
      }
      Ember.set(this, 'sortProperties', newSortProperties);
    },

    /**
     * Send `displayDataChangedAction`-action when user does sort of filter.
     * Action is sent if `displayDataChangedAction` is a closure-action
     *
     * @method userInteractionObserver
     * @returns {undefined}
     * @private
     */
    userInteractionObserver: function userInteractionObserver() {
      Ember.run.once(this, this.userInteractionObserverOnce);
    },

    /**
     * @method userInteractionObserverOnce
     * @returns {undefined}
     * @private
     */
    userInteractionObserverOnce: function userInteractionObserverOnce() {
      var action = Ember.get(this, 'displayDataChangedAction');
      var actionIsFunction = typeof action === 'function';

      if (actionIsFunction) {
        var columns = Ember.get(this, 'processedColumns');
        var settings = Ember.Object.create({
          sort: Ember.get(this, 'sortProperties'),
          currentPageNumber: Ember.get(this, 'currentPageNumber'),
          pageSize: parseInt(Ember.get(this, 'pageSize'), 10),
          filterString: Ember.get(this, 'filterString'),
          filteredContent: Ember.get(this, 'filteredContent'),
          selectedItems: Ember.get(this, 'selectedItems'),
          expandedItems: Ember.get(this, 'expandedItems'),
          columns: columns.map(function (c) {
            return Ember.getProperties(c, ['filterString', 'filterField', 'sortField', 'sorting', 'propertyName']);
          }),
          columnFilters: {}
        });
        columns.forEach(function (column) {
          if (!Ember.isBlank(Ember.get(column, 'filterString'))) {
            settings.columnFilters[Ember.get(column, 'propertyName')] = Ember.get(column, 'filterString');
          }
        });
        action(settings);
      }
    },

    /**
     * Send `columnsVisibilityChangedAction`-action when user changes which columns are visible.
     * Action is sent if `columnsVisibilityChangedAction` is a closure action
     *
     * @returns {undefined}
     * @method _sendColumnsVisibilityChangedAction
     * @private
     */
    _sendColumnsVisibilityChangedAction: function _sendColumnsVisibilityChangedAction() {
      var action = Ember.get(this, 'columnsVisibilityChangedAction');
      var actionIsFunction = typeof action === 'function';

      if (actionIsFunction) {
        var columns = Ember.get(this, 'processedColumns');
        var columnsVisibility = columns.map(function (column) {
          var options = Ember.getProperties(column, 'isHidden', 'mayBeHidden', 'propertyName');
          options.isHidden = !!options.isHidden;
          return options;
        });
        action(columnsVisibility);
      }
    },

    /**
     * Handler for global filter and filter by each column
     *
     * @method forceToFirstPage
     * @returns {undefined}
     * @private
     */
    forceToFirstPage: function forceToFirstPage() {
      Ember.set(this, 'currentPageNumber', 1);
      this.userInteractionObserver();
    },

    /**
     * Collapse open rows when user change page size or moved to the another page
     *
     * @method collapseRowOnNavigate
     * @returns {undefined}
     * @private
     */
    collapseRowOnNavigate: Ember.observer('currentPageNumber', 'pageSize', function () {
      Ember.get(this, 'expandedItems').clear();
    }),

    /**
     * Rebuild the whole table.
     * This can be called to force a complete re-render of the table.
     *
     * @method rebuildTable
     * @returns {undefined}
     */
    rebuildTable: function rebuildTable() {
      Ember.set(this, 'currentPageNumber', 1);
      this._clearFilters();
      this.setup();
    },

    /**
     * Update colspans for table header cells
     *
     * @method updateHeaderCellsColspan
     * @returns {undefined}
     * @private
     */
    updateHeaderCellsColspan: Ember.observer('processedColumns.@each.{isVisible,colspanForSortCell,colspanForFilterCell}', function () {
      Ember.run.once(this, this.updateHeaderCellsColspanOnce);
    }),

    /**
     * @method updateHeaderCellsColspanOnce
     * @returns {undefined}
     * @private
     */
    updateHeaderCellsColspanOnce: function updateHeaderCellsColspanOnce() {
      Ember.get(this, 'processedColumns').forEach(function (column, index, columns) {
        var colspanForSortCell = Ember.get(column, 'colspanForSortCell');
        var colspanForFilterCell = Ember.get(column, 'colspanForFilterCell');
        var nextColumnsForSortCell = columns.slice(index, index + colspanForSortCell).filter(function (c) {
          return Ember.get(c, 'isHidden');
        });
        var nextColumnsForFilterCell = columns.slice(index, index + colspanForFilterCell).filter(function (c) {
          return Ember.get(c, 'isHidden');
        });
        Ember.set(column, 'realColspanForSortCell', colspanForSortCell - Ember.get(nextColumnsForSortCell, 'length'));
        Ember.set(column, 'realColspanForFilterCell', colspanForFilterCell - Ember.get(nextColumnsForFilterCell, 'length'));
      });
    },

    /**
     * Clear all filters.
     *
     * @method _clearFilters
     * @returns {undefined}
     * @private
     */
    _clearFilters: function _clearFilters() {
      Ember.set(this, 'filterString', '');
      Ember.get(this, 'processedColumns').setEach('filterString', '');
    },
    willInsertElement: function willInsertElement() {
      var _this3 = this;

      Ember.get(this, 'forceToFirstPageProps').forEach(function (propertyName) {
        return _this3.addObserver(propertyName, _this3.forceToFirstPage);
      });
      return this._super.apply(this, arguments);
    },
    willDestroyElement: function willDestroyElement() {
      var _this4 = this;

      Ember.get(this, 'forceToFirstPageProps').forEach(function (propertyName) {
        return _this4.removeObserver(propertyName, _this4.forceToFirstPage);
      });
      var registerAPI = Ember.get(this, 'registerAPI');
      if (registerAPI) {
        registerAPI(null);
      }
      return this._super.apply(this, arguments);
    },

    /**
     * @type Object
     */
    actions: {

      /**
       * Send action to the outside context
       *
       * `sendAction` signature is the same as `Ember.Component#sendAction`
       *
       * @method actions.sendAction
       * @returns {undefined}
       */
      sendAction: function sendAction() {
        this.sendAction.apply(this, arguments);
      },

      /**
       * Toggle visibility for provided column
       *
       * It doesn't do nothing if column can't be hidden (see {{#crossLink 'Utils.ModelsTableColumn/mayBeHidden:property'}}mayBeHidden{{/crossLink}}). May trigger sending {{#crossLink 'Components.ModelsTable/columnsVisibilityChangedAction:property'}}columnsVisibilityChangedAction{{/crossLink}}
       *
       * @method actions.toggleHidden
       * @param {ModelsTableColumn} column
       * @returns {undefined}
       */
      toggleHidden: function toggleHidden(column) {
        if (Ember.get(column, 'mayBeHidden')) {
          column.toggleProperty('isHidden');
          this._sendColumnsVisibilityChangedAction();
        }
      },

      /**
       * Show all columns
       *
       * Set each column `isHidden` value to `false`. May trigger sending {{#crossLink 'Components.ModelsTable/columnsVisibilityChangedAction:property'}}columnsVisibilityChangedAction{{/crossLink}}
       *
       * @method actions.showAllColumns
       * @returns {undefined}
       */
      showAllColumns: function showAllColumns() {
        Ember.get(this, 'processedColumns').setEach('isHidden', false);
        this._sendColumnsVisibilityChangedAction();
      },

      /**
       * Hide all columns that may be hidden (see {{#crossLink 'Utils.ModelsTableColumn/mayBeHidden:property'}}mayBeHidden{{/crossLink}})
       *
       * May trigger sending {{#crossLink 'Components.ModelsTable/columnsVisibilityChangedAction:property'}}columnsVisibilityChangedAction{{/crossLink}}
       *
       * @method actions.hideAllColumns
       * @returns {undefined}
       */
      hideAllColumns: function hideAllColumns() {
        Ember.A(Ember.get(this, 'processedColumns').filterBy('mayBeHidden')).setEach('isHidden', true);
        this._sendColumnsVisibilityChangedAction();
      },

      /**
       * Restore columns visibility values according to their default visibility settings (see {{#crossLink 'Utils.ModelsTableColumn/defaultVisible:property'}}defaultVisible{{/crossLink}})
       *
       * May trigger sending {{#crossLink 'Components.ModelsTable/columnsVisibilityChangedAction:property'}}columnsVisibilityChangedAction{{/crossLink}}
       *
       * @method actions.restoreDefaultVisibility
       * @returns {undefined}
       */
      restoreDefaultVisibility: function restoreDefaultVisibility() {
        var _this5 = this;

        Ember.get(this, 'processedColumns').forEach(function (c) {
          Ember.set(c, 'isHidden', !Ember.get(c, 'defaultVisible'));
          _this5._sendColumnsVisibilityChangedAction();
        });
      },

      /**
       * Toggle visibility for every column in the selected columns set
       *
       * It ignore columns that can't be hidden (see {{#crossLink 'Utils.ModelsTableColumn/mayBeHidden:property'}}mayBeHidden{{/crossLink}}). May trigger sending {{#crossLink 'Components.ModelsTable/columnsVisibilityChangedAction:property'}}columnsVisibilityChangedAction{{/crossLink}}
       *
       * @method actions.toggleColumnSet
       * @returns {undefined}
       */
      toggleColumnSet: function toggleColumnSet() {
        var _this6 = this;

        var _ref = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
            _ref$showColumns = _ref.showColumns,
            showColumns = _ref$showColumns === undefined ? [] : _ref$showColumns,
            hideOtherColumns = _ref.hideOtherColumns,
            _ref$toggleSet = _ref.toggleSet,
            toggleSet = _ref$toggleSet === undefined ? false : _ref$toggleSet;

        var columns = Ember.get(this, 'processedColumns');

        // If hideOtherColumns is not set, default to true if toggleSet=false, else to false
        hideOtherColumns = Ember.isNone(hideOtherColumns) ? !toggleSet : hideOtherColumns;

        // If showColumns is a function, call it
        if (Ember.typeOf(showColumns) === 'function') {
          return Ember.run(this, showColumns, columns);
        }

        var setColumns = Ember.A([]);
        var otherColumns = Ember.A([]);

        columns.forEach(function (column) {
          var columnId = Ember.get(column, 'propertyName');

          if (!columnId || !Ember.get(column, 'mayBeHidden')) {
            return;
          }

          showColumns = Ember.A(showColumns);
          if (showColumns.includes(columnId)) {
            setColumns.pushObject(column);
          } else {
            otherColumns.pushObject(column);
          }
        });

        // By default, all columns should always be set to visible
        // However, if `toggleSet=true`, then the set should be toggled between visible/hidden
        // In this case, if one of the set columns is hidden, make them all visible, else hide them
        var targetVisibility = true;
        if (toggleSet) {
          targetVisibility = !!setColumns.findBy('isVisible', false);
        }

        setColumns.forEach(function (column) {
          var columnId = Ember.get(column, 'propertyName');
          if (showColumns.includes(columnId) && Ember.get(column, 'isVisible') !== targetVisibility) {
            _this6.send('toggleHidden', column);
          }
        });

        if (hideOtherColumns) {
          otherColumns.forEach(function (column) {
            var columnId = Ember.get(column, 'propertyName');

            if (!showColumns.includes(columnId) && Ember.get(column, 'isVisible')) {
              _this6.send('toggleHidden', column);
            }
          });
        }
      },

      /**
       * Pagination click-handler
       *
       * It moves user to the selected page. Check [models-table/pagination-numeric](Components.ModelsTablePaginationNumeric.html) and [models-table/pagination-simple](Components.ModelsTablePaginationSimple.html) for usage examples. May trigger sending {{#crossLink 'Components.ModelsTable/displayDataChangedAction:property'}}displayDataChangedAction{{/crossLink}}
       *
       * @param {number} pageNumber
       * @method actions.gotoCustomPage
       * @returns {undefined}
       */
      gotoCustomPage: function gotoCustomPage(pageNumber) {
        Ember.set(this, 'currentPageNumber', pageNumber);
        this.userInteractionObserver();
      },

      /**
       * Sort selected column by {{#crossLink 'Utils.ModelsTableColumn/sortedBy:property'}}sortedBy{{/crossLink}} or {{#crossLink 'Utils.ModelsTableColumn/propertyName:property'}}propertyName{{/crossLink}}
       *
       * It will drop sorting for other columns if {{#crossLink 'Components.ModelsTable/multipleColumnsSorting:property'}}multipleColumnsSorting{{/crossLink}} is set to `false`. It will add new sorting if {{#crossLink 'Components.ModelsTable/multipleColumnsSorting:property'}}multipleColumnsSorting{{/crossLink}} is set to `true`. May trigger sending {{#crossLink 'Components.ModelsTable/displayDataChangedAction:property'}}displayDataChangedAction{{/crossLink}}. Table will be dropped to the first page if sorting is done
       *
       * For multiColumns-sorting calling sort will change sort-order. E.g.:
       *
       * ```js
       * sortProperties = ['a:asc', 'b:asc', 'c:desc'];
       * sort({propertyName: 'b'}); // sortProperties now is ['a:asc', 'c:desc', 'b:desc']
       * ```
       *
       * @method actions.sort
       * @param {ModelsTableColumn} column
       * @returns {undefined}
       */
      sort: function sort(column) {
        var sortMap = {
          none: 'asc',
          asc: 'desc',
          desc: 'none'
        };
        var sortedBy = Ember.get(column, 'sortedBy') || Ember.get(column, 'propertyName');
        if (!sortedBy) {
          return;
        }
        var currentSorting = Ember.get(column, 'sorting') || 'none';
        var newSorting = sortMap[currentSorting.toLowerCase()];
        if (sortedBy === Ember.get(this, 'currentGroupingPropertyName')) {
          var sortByGroupedFieldDirection = Ember.get(this, 'sortByGroupedFieldDirection');
          newSorting = sortByGroupedFieldDirection === 'asc' ? 'desc' : 'asc';
          Ember.set(this, 'sortByGroupedFieldDirection', newSorting);
          return;
        }
        var sortingArgs = [column, sortedBy, newSorting];
        if (Ember.get(this, 'multipleColumnsSorting')) {
          this._multiColumnsSorting.apply(this, sortingArgs);
        } else {
          this._singleColumnSorting.apply(this, sortingArgs);
        }
        Ember.set(this, 'currentPageNumber', 1);
        this.userInteractionObserver();
      },

      /**
       * Expand selected row
       *
       * It will cause expandedRowComponent to be used for it. It will collapse already expanded row if {{#crossLink 'Components.ModelsTable/multipleExpand:property'}}multipleExpand{{/crossLink}} is set to `false`. Expanding is assigned to the record itself and not their index. So, if page #1 has first row expanded and user is moved to any another page, first row on new page won't be expanded. But when user will be back to the first page, first row will be expanded. May trigger sending {{#crossLink 'Components.ModelsTable/displayDataChangedAction:property'}}displayDataChangedAction{{/crossLink}}
       *
       * @param {number} index
       * @param {object} dataItem
       * @returns {undefined}
       * @method actions.expandRow
       */
      expandRow: function expandRow(index, dataItem) {
        true && !(Ember.typeOf(index) === 'number') && Ember.assert('row index should be numeric', Ember.typeOf(index) === 'number');

        var multipleExpand = Ember.get(this, 'multipleExpand');
        var expandedItems = Ember.get(this, 'expandedItems');
        if (!multipleExpand && Ember.get(expandedItems, 'length') === 1) {
          expandedItems.clear();
        }
        expandedItems.pushObject(dataItem);
        this.userInteractionObserver();
      },

      /**
       * Collapse selected row
       *
       * May trigger sending {{#crossLink 'Components.ModelsTable/displayDataChangedAction:property'}}displayDataChangedAction{{/crossLink}}
       *
       * @param {number} index
       * @param {object} dataItem
       * @returns {undefined}
       * @method actions.collapseRow
       */
      collapseRow: function collapseRow(index, dataItem) {
        true && !(Ember.typeOf(index) === 'number') && Ember.assert('row index should be numeric', Ember.typeOf(index) === 'number');

        Ember.get(this, 'expandedItems').removeObject(dataItem);
        this.userInteractionObserver();
      },

      /**
       * Expand all rows in the current page
       *
       * It works only if {{#crossLink 'Components.ModelsTable/multipleExpand:property'}}multipleExpand{{/crossLink}} is set to `true`. May trigger sending {{#crossLink 'Components.ModelsTable/displayDataChangedAction:property'}}displayDataChangedAction{{/crossLink}}
       *
       * @method actions.expandAllRows
       * @returns {undefined}
       */
      expandAllRows: function expandAllRows() {
        var multipleExpand = Ember.get(this, 'multipleExpand');
        var visibleContent = Ember.get(this, 'visibleContent');
        if (multipleExpand) {
          if (Ember.get(this, 'useDataGrouping')) {
            Ember.get(this, 'expandedItems').pushObjects(Ember.A(objToArray(Ember.get(this, 'groupedVisibleContent'))));
          } else {
            Ember.get(this, 'expandedItems').pushObjects(Ember.A(visibleContent.slice()));
          }
          this.userInteractionObserver();
        }
      },

      /**
       * Collapse all rows in the current page
       *
       * May trigger sending {{#crossLink 'Components.ModelsTable/displayDataChangedAction:property'}}displayDataChangedAction{{/crossLink}}
       *
       * @method actions.collapseAllRows
       * @returns {undefined}
       */
      collapseAllRows: function collapseAllRows() {
        Ember.get(this, 'expandedItems').clear();
        this.userInteractionObserver();
      },

      /**
       * Handler for row-click
       *
       * Toggle <code>selected</code>-state for row. Select only one or multiple rows depends on {{#crossLink 'Components.ModelsTable/multipleSelect:property'}}multipleSelect{{/crossLink}} value. May trigger sending {{#crossLink 'Components.ModelsTable/displayDataChangedAction:property'}}displayDataChangedAction{{/crossLink}}
       *
       * @param {number} index
       * @param {object} dataItem
       * @returns {undefined}
       * @method actions.clickOnRow
       */
      clickOnRow: function clickOnRow(index, dataItem) {
        true && !(Ember.typeOf(index) === 'number') && Ember.assert('row index should be numeric', Ember.typeOf(index) === 'number');

        if (Ember.get(this, 'selectRowOnClick')) {
          var multipleSelect = Ember.get(this, 'multipleSelect');
          var selectedItems = Ember.get(this, 'selectedItems');
          if (selectedItems.includes(dataItem)) {
            selectedItems.removeObject(dataItem);
          } else {
            if (!multipleSelect && Ember.get(selectedItems, 'length') === 1) {
              Ember.get(this, 'selectedItems').clear();
            }
            Ember.get(this, 'selectedItems').pushObject(dataItem);
          }
        }
        this.userInteractionObserver();
      },

      /**
       * Handler for double-click on row
       *
       * May trigger sending {{#crossLink 'Components.ModelsTable/rowDoubleClickAction:property'}}rowDoubleClickAction{{/crossLink}}
       *
       * @param {number} index
       * @param {object} dataItem
       * @returns {undefined}
       * @method actions.doubleClickOnRow
       */
      doubleClickOnRow: function doubleClickOnRow(index, dataItem) {
        true && !(Ember.typeOf(index) === 'number') && Ember.assert('row index should be numeric', Ember.typeOf(index) === 'number');

        var action = Ember.get(this, 'rowDoubleClickAction');
        var actionIsFunction = typeof action === 'function';
        if (actionIsFunction) {
          action(index, dataItem);
        }
      },

      /**
       * Handler for row-hover
       *
       * May trigger sending {{#crossLink 'Components.ModelsTable/rowHoverAction:property'}}rowHoverAction{{/crossLink}}
       *
       * @param {number} index
       * @param {object} dataItem
       * @returns {undefined}
       * @method actions.hoverOnRow
       */
      hoverOnRow: function hoverOnRow(index, dataItem) {
        true && !(Ember.typeOf(index) === 'number') && Ember.assert('row index should be numeric', Ember.typeOf(index) === 'number');

        var action = Ember.get(this, 'rowHoverAction');
        var actionIsFunction = typeof action === 'function';
        if (actionIsFunction) {
          action(index, dataItem);
        }
      },

      /**
       * Handler for row-hover
       *
       * May trigger sending {{#crossLink 'Components.ModelsTable/rowHoverAction:property'}}rowOutAction{{/crossLink}}
       *
       * @param {number} index
       * @param {object} dataItem
       * @returns {undefined}
       * @method actions.outRow
       */
      outRow: function outRow(index, dataItem) {
        true && !(Ember.typeOf(index) === 'number') && Ember.assert('row index should be numeric', Ember.typeOf(index) === 'number');

        var action = Ember.get(this, 'rowOutAction');
        var actionIsFunction = typeof action === 'function';
        if (actionIsFunction) {
          action(index, dataItem);
        }
      },

      /**
       * Clear all column filters and global filter
       *
       * May trigger sending {{#crossLink 'Components.ModelsTable/displayDataChangedAction:property'}}displayDataChangedAction{{/crossLink}}
       *
       * @returns {undefined}
       * @method actions.clearFilters
       */
      clearFilters: function clearFilters() {
        this._clearFilters();
      },

      /**
       * Select/deselect all rows
       *
       * May trigger sending {{#crossLink 'Components.ModelsTable/displayDataChangedAction:property'}}displayDataChangedAction{{/crossLink}}
       *
       * @method actions.toggleAllSelection
       * @returns {undefined}
       */
      toggleAllSelection: function toggleAllSelection() {
        var selectedItems = Ember.get(this, 'selectedItems');
        var data = Ember.get(this, 'data');
        var allSelectedBefore = Ember.get(selectedItems, 'length') === Ember.get(data, 'length');
        Ember.get(this, 'selectedItems').clear();
        if (!allSelectedBefore) {
          Ember.get(this, 'selectedItems').pushObjects(data);
        }
        this.userInteractionObserver();
      },

      /**
       * Expand or collapse all rows in the rows group
       *
       * **IMPORTANT** `multipleExpand` should be set to `true` otherwise this action won't do anything
       *
       * @method actions.toggleGroupedRowsExpands
       * @param {*} groupedValue
       * @returns {undefined}
       */
      toggleGroupedRowsExpands: function toggleGroupedRowsExpands(groupedValue) {
        if (!Ember.get(this, 'multipleExpand')) {
          return;
        }
        var expandedItems = Ember.get(this, 'expandedItems');
        var currentGroupingPropertyName = Ember.get(this, 'currentGroupingPropertyName');
        var groupedItems = Ember.get(this, 'groupedArrangedContent').filterBy(currentGroupingPropertyName, groupedValue);
        var notExpandedGroupItems = groupedItems.filter(function (record) {
          return expandedItems.indexOf(record) === -1;
        });
        if (Ember.get(notExpandedGroupItems, 'length')) {
          var toPush = notExpandedGroupItems.filter(function (record) {
            return expandedItems.indexOf(record) === -1;
          });
          Ember.get(this, 'expandedItems').pushObjects(toPush);
        } else {
          groupedItems.forEach(function (record) {
            return expandedItems.removeObject(record);
          });
        }
        this.userInteractionObserver();
      },

      /**
       * Select/deselect rows from the rows group
       *
       * **IMPORTANT** `multipleSelect` should be set to `true` otherwise this action won't do anything
       *
       * May trigger sending {{#crossLink 'Components.ModelsTable/displayDataChangedAction:property'}}displayDataChangedAction{{/crossLink}}
       *
       * @method actions.toggleGroupedRowsSelection
       * @param {*} groupedValue
       * @returns {undefined}
       */
      toggleGroupedRowsSelection: function toggleGroupedRowsSelection(groupedValue) {
        if (!Ember.get(this, 'multipleSelect')) {
          return;
        }
        var selectedItems = Ember.get(this, 'selectedItems');
        var currentGroupingPropertyName = Ember.get(this, 'currentGroupingPropertyName');
        var groupedItems = Ember.get(this, 'groupedArrangedContent').filterBy(currentGroupingPropertyName, groupedValue);
        var notSelectedGroupItems = groupedItems.filter(function (record) {
          return selectedItems.indexOf(record) === -1;
        });
        if (Ember.get(notSelectedGroupItems, 'length')) {
          var toPush = notSelectedGroupItems.filter(function (record) {
            return selectedItems.indexOf(record) === -1;
          });
          Ember.get(this, 'selectedItems').pushObjects(toPush);
        } else {
          groupedItems.forEach(function (record) {
            return selectedItems.removeObject(record);
          });
        }
        this.userInteractionObserver();
      },

      /**
       * Collapse or expand rows group
       *
       * @method actions.toggleGroupedRows
       * @param {*} groupedValue
       * @returns {undefined}
       */
      toggleGroupedRows: function toggleGroupedRows(groupedValue) {
        var collapsedGroupValues = Ember.get(this, 'collapsedGroupValues');
        if (collapsedGroupValues.includes(groupedValue)) {
          collapsedGroupValues.removeObject(groupedValue);
        } else {
          Ember.get(this, 'collapsedGroupValues').pushObject(groupedValue);
        }
      }
    }

  });
});