define('ember-inline-edit/components/ember-inline-edit', ['exports', 'ember'], function (exports, _ember) {
  'use strict';

  var _Em = Em;
  var get = _Em.get;
  var set = _Em.set;
  var computed = _Em.computed;
  var on = _Em.on;
  var getWithDefault = _Em.getWithDefault;
  var run = _Em.run;
  var Logger = _Em.Logger;
  var info = Logger.info;

  exports['default'] = _ember['default'].Component.extend({
    classNames: ['ember-inline-edit'],
    classNameBindings: ['isEditing:is-editing'],

    textFields: ['search', 'url', 'text', 'phone', 'email', 'number'],
    textAreaFields: ['textarea'],

    isEditing: false,

    field: 'text',
    value: null,
    placeholder: 'Not Provided',

    valueIsEmpty: computed.empty('value'),

    setup: on('didInsertElement', function () {
      this._handleClick = this._handleClick.bind(this);
      this._handleKeyup = this._handleKeyup.bind(this);
      this._setupEventHandlers();
    }),

    _setupEventHandlers: function _setupEventHandlers() {
      $(document).on('click', this._handleClick);
      $(this.element).on('keyup', '.ember-inline-edit-input', this._handleKeyup);
    },

    _handleClick: function _handleClick(e) {
      var isEditing = get(this, 'isEditing');
      var editor = $(this.element);
      var target = $(e.target);
      var isInside = editor.is(target) || editor.has(target).length > 0;

      if (isInside && !isEditing) {
        if (get(this, 'showEditButton')) return;
        this.send('startEditing');
      } else if (!isInside && isEditing) {
        this.send('close');
      }
    },

    _handleKeyup: function _handleKeyup(e) {
      var isEditing = get(this, 'isEditing');
      var isEnter = e.which === 13 || e.keyCode === 13;
      var isEsc = e.which === 27 || e.keyCode === 27;

      if (!isEditing) return;

      if (isEnter) {
        this.send('save');
      } else if (isEsc) {
        this.send('close');
      }
    },

    _focusOnInput: function _focusOnInput() {
      run.next(function () {
        $('.ember-inline-edit-input').focus();
      });
    },

    _teardown: on('willDestroyElement', function () {
      $(document).off('click', this._handleClick);
      $(this.element).off('keyup', '.ember-inline-edit-input', this._handleKeyup);
    }),

    actions: {
      save: function save() {
        info('[ember-inline-edit] Got the `onSave` action');
        this.sendAction('onSave', this.get('value'));
        set(this, 'isEditing', false);
      },

      startEditing: function startEditing() {
        info('[ember-inline-edit] Got the `startEditing` action');
        set(this, 'isEditing', true);
        this._focusOnInput();
      },

      close: function close() {
        info('[ember-inline-edit] Got the `onClose` action');
        this.sendAction('onClose');
        set(this, 'isEditing', false);
      },

      setValue: function setValue(value) {
        set(this, 'value', value);
      }
    }
  });
});