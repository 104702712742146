define("ember-models-table/templates/components/models-table/cell", ["exports"], function (exports) {
  "use strict";

  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      return {
        meta: {
          "fragmentReason": {
            "name": "missing-wrapper",
            "problems": ["wrong-type"]
          },
          "revision": "Ember@2.5.0",
          "loc": {
            "source": null,
            "start": {
              "line": 1,
              "column": 0
            },
            "end": {
              "line": 26,
              "column": 0
            }
          },
          "moduleName": "modules/ember-models-table/templates/components/models-table/cell.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
          return morphs;
        },
        statements: [["inline", "yield", [["subexpr", "hash", [], ["record", ["get", "record", ["loc", [null, [4, 13], [4, 19]]]], "index", ["get", "index", ["loc", [null, [5, 12], [5, 17]]]], "column", ["get", "column", ["loc", [null, [6, 13], [6, 19]]]], "componentToRender", ["get", "componentToRender", ["loc", [null, [7, 24], [7, 41]]]], "sendAction", ["get", "sendAction", ["loc", [null, [8, 17], [8, 27]]]], "expandRow", ["get", "expandRow", ["loc", [null, [9, 16], [9, 25]]]], "collapseRow", ["get", "collapseRow", ["loc", [null, [10, 18], [10, 29]]]], "expandAllRows", ["get", "expandAllRows", ["loc", [null, [11, 20], [11, 33]]]], "collapseAllRows", ["get", "collapseAllRows", ["loc", [null, [12, 22], [12, 37]]]], "editRow", ["get", "editRow", ["loc", [null, [13, 14], [13, 21]]]], "cancelEditRow", ["get", "cancelEditRow", ["loc", [null, [14, 20], [14, 33]]]], "themeInstance", ["get", "themeInstance", ["loc", [null, [15, 20], [15, 33]]]], "clickOnRow", ["get", "clickOnRow", ["loc", [null, [16, 17], [16, 27]]]], "isExpanded", ["get", "isExpanded", ["loc", [null, [17, 17], [17, 27]]]], "isSelected", ["get", "isSelected", ["loc", [null, [18, 17], [18, 27]]]], "isEditRow", ["get", "isEditRow", ["loc", [null, [19, 16], [19, 25]]]], "editRow", ["get", "editRow", ["loc", [null, [20, 14], [20, 21]]]], "saveRow", ["get", "saveRow", ["loc", [null, [21, 14], [21, 21]]]], "cancelEditRow", ["get", "cancelEditRow", ["loc", [null, [22, 20], [22, 33]]]], "isColumnEditable", ["get", "isColumnEditable", ["loc", [null, [23, 23], [23, 39]]]]], ["loc", [null, [3, 4], [24, 5]]]]], [], ["loc", [null, [2, 2], [25, 4]]]]],
        locals: [],
        templates: []
      };
    })();
    var child1 = (function () {
      var child0 = (function () {
        return {
          meta: {
            "fragmentReason": false,
            "revision": "Ember@2.5.0",
            "loc": {
              "source": null,
              "start": {
                "line": 27,
                "column": 2
              },
              "end": {
                "line": 48,
                "column": 2
              }
            },
            "moduleName": "modules/ember-models-table/templates/components/models-table/cell.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("    ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
            return morphs;
          },
          statements: [["inline", "component", [["get", "componentToRender", ["loc", [null, [29, 6], [29, 23]]]]], ["record", ["subexpr", "@mut", [["get", "record", ["loc", [null, [30, 13], [30, 19]]]]], [], []], "index", ["subexpr", "@mut", [["get", "index", ["loc", [null, [31, 12], [31, 17]]]]], [], []], "column", ["subexpr", "@mut", [["get", "column", ["loc", [null, [32, 13], [32, 19]]]]], [], []], "isEditRow", ["subexpr", "@mut", [["get", "isEditRow", ["loc", [null, [33, 16], [33, 25]]]]], [], []], "editRow", ["subexpr", "@mut", [["get", "editRow", ["loc", [null, [34, 14], [34, 21]]]]], [], []], "saveRow", ["subexpr", "@mut", [["get", "saveRow", ["loc", [null, [35, 14], [35, 21]]]]], [], []], "cancelEditRow", ["subexpr", "@mut", [["get", "cancelEditRow", ["loc", [null, [36, 20], [36, 33]]]]], [], []], "sendAction", ["subexpr", "@mut", [["get", "sendAction", ["loc", [null, [37, 17], [37, 27]]]]], [], []], "expandRow", ["subexpr", "@mut", [["get", "expandRow", ["loc", [null, [38, 16], [38, 25]]]]], [], []], "collapseRow", ["subexpr", "@mut", [["get", "collapseRow", ["loc", [null, [39, 18], [39, 29]]]]], [], []], "expandAllRows", ["subexpr", "@mut", [["get", "expandAllRows", ["loc", [null, [40, 20], [40, 33]]]]], [], []], "collapseAllRows", ["subexpr", "@mut", [["get", "collapseAllRows", ["loc", [null, [41, 22], [41, 37]]]]], [], []], "clickOnRow", ["subexpr", "@mut", [["get", "clickOnRow", ["loc", [null, [42, 17], [42, 27]]]]], [], []], "isExpanded", ["subexpr", "@mut", [["get", "isExpanded", ["loc", [null, [43, 17], [43, 27]]]]], [], []], "isSelected", ["subexpr", "@mut", [["get", "isSelected", ["loc", [null, [44, 17], [44, 27]]]]], [], []], "isColumnEditable", ["subexpr", "@mut", [["get", "isColumnEditable", ["loc", [null, [45, 23], [45, 39]]]]], [], []], "themeInstance", ["subexpr", "@mut", [["get", "themeInstance", ["loc", [null, [46, 20], [46, 33]]]]], [], []]], ["loc", [null, [28, 4], [47, 6]]]]],
          locals: [],
          templates: []
        };
      })();
      return {
        meta: {
          "fragmentReason": false,
          "revision": "Ember@2.5.0",
          "loc": {
            "source": null,
            "start": {
              "line": 26,
              "column": 0
            },
            "end": {
              "line": 49,
              "column": 0
            }
          },
          "moduleName": "modules/ember-models-table/templates/components/models-table/cell.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [["block", "if", [["get", "componentToRender", ["loc", [null, [27, 8], [27, 25]]]]], [], 0, null, ["loc", [null, [27, 2], [48, 9]]]]],
        locals: [],
        templates: [child0]
      };
    })();
    return {
      meta: {
        "fragmentReason": {
          "name": "missing-wrapper",
          "problems": ["wrong-type"]
        },
        "revision": "Ember@2.5.0",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 49,
            "column": 7
          }
        },
        "moduleName": "modules/ember-models-table/templates/components/models-table/cell.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var morphs = new Array(1);
        morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
        dom.insertBoundary(fragment, 0);
        dom.insertBoundary(fragment, null);
        return morphs;
      },
      statements: [["block", "if", [["get", "hasBlock", ["loc", [null, [1, 6], [1, 14]]]]], [], 0, 1, ["loc", [null, [1, 0], [49, 7]]]]],
      locals: [],
      templates: [child0, child1]
    };
  })());
});