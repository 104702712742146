define('ember-models-table/components/models-table/themes/semanticui/select', ['exports', 'ember-models-table/components/models-table/select', 'ember-models-table/templates/components/models-table/select'], function (exports, _emberModelsTableComponentsModelsTableSelect, _emberModelsTableTemplatesComponentsModelsTableSelect) {
  'use strict';

  exports['default'] = _emberModelsTableComponentsModelsTableSelect['default'].extend({

    layout: _emberModelsTableTemplatesComponentsModelsTableSelect['default'],

    classNames: ['ui fluid dropdown']

  });
});