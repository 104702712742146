define('ember-models-table/components/models-table/cell-content-edit', ['exports', 'ember-models-table/templates/components/models-table/cell-content-edit'], function (exports, _emberModelsTableTemplatesComponentsModelsTableCellContentEdit) {
  'use strict';

  /**
   * Component for cells in the edit-mode.
   *
   * Properties and event-handlers from [models-table/cell](Components.ModelsTableCell.html) are bound here
   *
   * @namespace Components
   * @class ModelsTableCellContentEdit
   * @extends Ember.Component
   */
  exports['default'] = Ember.Component.extend({
    layout: _emberModelsTableTemplatesComponentsModelsTableCellContentEdit['default'],

    init: function init() {
      Ember.set(this, 'tagName', Ember.get(this, 'themeInstance.tagNames.cell-content'));
      this._super.apply(this, arguments);
    }
  });
});