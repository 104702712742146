define("ember-models-table/templates/components/models-table/table-footer", ["exports"], function (exports) {
  "use strict";

  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      var child0 = (function () {
        return {
          meta: {
            "fragmentReason": false,
            "revision": "Ember@2.5.0",
            "loc": {
              "source": null,
              "start": {
                "line": 16,
                "column": 2
              },
              "end": {
                "line": 18,
                "column": 2
              }
            },
            "moduleName": "modules/ember-models-table/templates/components/models-table/table-footer.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("    ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
            return morphs;
          },
          statements: [["inline", "yield", [["get", "tf", ["loc", [null, [17, 12], [17, 14]]]]], [], ["loc", [null, [17, 4], [17, 16]]]]],
          locals: [],
          templates: []
        };
      })();
      var child1 = (function () {
        var child0 = (function () {
          var child0 = (function () {
            return {
              meta: {
                "fragmentReason": false,
                "revision": "Ember@2.5.0",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 21,
                    "column": 8
                  },
                  "end": {
                    "line": 31,
                    "column": 8
                  }
                },
                "moduleName": "modules/ember-models-table/templates/components/models-table/table-footer.hbs"
              },
              isEmpty: false,
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("          ");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
                return morphs;
              },
              statements: [["inline", "component", [["get", "column.componentForFooterCell", ["loc", [null, [23, 12], [23, 41]]]]], ["selectedItems", ["subexpr", "@mut", [["get", "selectedItems", ["loc", [null, [24, 26], [24, 39]]]]], [], []], "expandedItems", ["subexpr", "@mut", [["get", "expandedItems", ["loc", [null, [25, 26], [25, 39]]]]], [], []], "data", ["subexpr", "@mut", [["get", "data", ["loc", [null, [26, 17], [26, 21]]]]], [], []], "mappedSelectedItems", ["subexpr", "if", [["get", "column.propertyName", ["loc", [null, [27, 36], [27, 55]]]], ["subexpr", "map-by", [["get", "column.propertyName", ["loc", [null, [27, 64], [27, 83]]]], ["get", "selectedItems", ["loc", [null, [27, 84], [27, 97]]]]], [], ["loc", [null, [27, 56], [27, 98]]]]], [], ["loc", [null, [27, 32], [27, 99]]]], "mappedExpandedItems", ["subexpr", "if", [["get", "column.propertyName", ["loc", [null, [28, 36], [28, 55]]]], ["subexpr", "map-by", [["get", "column.propertyName", ["loc", [null, [28, 64], [28, 83]]]], ["get", "expandedItems", ["loc", [null, [28, 84], [28, 97]]]]], [], ["loc", [null, [28, 56], [28, 98]]]]], [], ["loc", [null, [28, 32], [28, 99]]]], "mappedData", ["subexpr", "if", [["get", "column.propertyName", ["loc", [null, [29, 27], [29, 46]]]], ["subexpr", "map-by", [["get", "column.propertyName", ["loc", [null, [29, 55], [29, 74]]]], ["get", "data", ["loc", [null, [29, 75], [29, 79]]]]], [], ["loc", [null, [29, 47], [29, 80]]]]], [], ["loc", [null, [29, 23], [29, 81]]]]], ["loc", [null, [22, 10], [30, 12]]]]],
              locals: [],
              templates: []
            };
          })();
          var child1 = (function () {
            return {
              meta: {
                "fragmentReason": false,
                "revision": "Ember@2.5.0",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 31,
                    "column": 8
                  },
                  "end": {
                    "line": 33,
                    "column": 8
                  }
                },
                "moduleName": "modules/ember-models-table/templates/components/models-table/table-footer.hbs"
              },
              isEmpty: false,
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("          ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("td");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes() {
                return [];
              },
              statements: [],
              locals: [],
              templates: []
            };
          })();
          return {
            meta: {
              "fragmentReason": false,
              "revision": "Ember@2.5.0",
              "loc": {
                "source": null,
                "start": {
                  "line": 20,
                  "column": 6
                },
                "end": {
                  "line": 34,
                  "column": 6
                }
              },
              "moduleName": "modules/ember-models-table/templates/components/models-table/table-footer.hbs"
            },
            isEmpty: false,
            arity: 1,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
              dom.insertBoundary(fragment, 0);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [["block", "if", [["get", "column.componentForFooterCell", ["loc", [null, [21, 14], [21, 43]]]]], [], 0, 1, ["loc", [null, [21, 8], [33, 15]]]]],
            locals: ["column"],
            templates: [child0, child1]
          };
        })();
        return {
          meta: {
            "fragmentReason": false,
            "revision": "Ember@2.5.0",
            "loc": {
              "source": null,
              "start": {
                "line": 18,
                "column": 2
              },
              "end": {
                "line": 36,
                "column": 2
              }
            },
            "moduleName": "modules/ember-models-table/templates/components/models-table/table-footer.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("    ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("tr");
            var el2 = dom.createTextNode("\n");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("    ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 1, 1);
            return morphs;
          },
          statements: [["block", "each", [["get", "visibleProcessedColumns", ["loc", [null, [20, 14], [20, 37]]]]], [], 0, null, ["loc", [null, [20, 6], [34, 15]]]]],
          locals: [],
          templates: [child0]
        };
      })();
      return {
        meta: {
          "fragmentReason": {
            "name": "missing-wrapper",
            "problems": ["wrong-type"]
          },
          "revision": "Ember@2.5.0",
          "loc": {
            "source": null,
            "start": {
              "line": 1,
              "column": 0
            },
            "end": {
              "line": 37,
              "column": 0
            }
          },
          "moduleName": "modules/ember-models-table/templates/components/models-table/table-footer.hbs"
        },
        isEmpty: false,
        arity: 1,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [["block", "if", [["get", "hasBlock", ["loc", [null, [16, 8], [16, 16]]]]], [], 0, 1, ["loc", [null, [16, 2], [36, 9]]]]],
        locals: ["tf"],
        templates: [child0, child1]
      };
    })();
    return {
      meta: {
        "fragmentReason": {
          "name": "missing-wrapper",
          "problems": ["wrong-type"]
        },
        "revision": "Ember@2.5.0",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 37,
            "column": 9
          }
        },
        "moduleName": "modules/ember-models-table/templates/components/models-table/table-footer.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var morphs = new Array(1);
        morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
        dom.insertBoundary(fragment, 0);
        dom.insertBoundary(fragment, null);
        return morphs;
      },
      statements: [["block", "with", [["subexpr", "hash", [], ["goToPage", ["get", "goToPage", ["loc", [null, [2, 11], [2, 19]]]], "clearFilters", ["get", "clearFilters", ["loc", [null, [3, 15], [3, 27]]]], "sendAction", ["get", "sendAction", ["loc", [null, [4, 13], [4, 23]]]], "expandRow", ["get", "expandRow", ["loc", [null, [5, 12], [5, 21]]]], "collapseRow", ["get", "collapseRow", ["loc", [null, [6, 14], [6, 25]]]], "expandAllRows", ["get", "expandAllRows", ["loc", [null, [7, 16], [7, 29]]]], "collapseAllRows", ["get", "collapseAllRows", ["loc", [null, [8, 18], [8, 33]]]], "themeInstance", ["get", "themeInstance", ["loc", [null, [9, 16], [9, 29]]]], "selectedItems", ["get", "selectedItems", ["loc", [null, [10, 16], [10, 29]]]], "expandedItems", ["get", "expandedItems", ["loc", [null, [11, 16], [11, 29]]]], "clearFilters", ["get", "clearFilters", ["loc", [null, [12, 15], [12, 27]]]], "visibleProcessedColumns", ["get", "visibleProcessedColumns", ["loc", [null, [13, 26], [13, 49]]]], "data", ["get", "data", ["loc", [null, [14, 7], [14, 11]]]]], ["loc", [null, [1, 8], [15, 1]]]]], [], 0, null, ["loc", [null, [1, 0], [37, 9]]]]],
      locals: [],
      templates: [child0]
    };
  })());
});