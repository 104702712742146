define('ember-tooltips/components/tooltip-on-parent', ['exports', 'ember', 'ember-tooltips/templates/components/tooltip-on-parent'], function (exports, _ember, _emberTooltipsTemplatesComponentsTooltipOnParent) {
  'use strict';

  var computed = _ember['default'].computed;
  var run = _ember['default'].run;
  var get = _ember['default'].get;

  exports['default'] = _ember['default'].Component.extend({
    attributeBindings: ['style'],
    layout: _emberTooltipsTemplatesComponentsTooltipOnParent['default'],

    style: computed(function () {
      return _ember['default'].String.htmlSafe('display:none;');
    }),

    init: function init() {
      var _this = this;

      for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
        args[_key] = arguments[_key];
      }

      this._super(args);

      run.schedule('afterRender', function () {
        var parentView = _this.get('parentView');

        if (!parentView) {
          console.warn('No parentView found');
        } else if (parentView.renderTooltip) {
          parentView.renderTooltip(_this);
        } else {
          console.warn('No renderTooltip method found on the parent view of the {{tooltip-on-parent}} component');
        }

        _this.remove();
      });
    }

  });
});